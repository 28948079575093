// Array of trending date spots in San Francisco
const sfDateSpots = [
  {
    name: "Golden Gate Park",
    address: "501 Stanyan St, San Francisco, CA",
    locationDescription: "Expansive urban park in Western San Francisco",
    highlights: [
      "Beautiful botanical gardens with intimate garden paths",
      "Rowboats on Stow Lake for romantic paddling",
      "Japanese Tea Garden offers serene ambiance"
    ],
    hours: "6:00 AM to 10:00 PM daily",
    specialNotes: "Outdoor venue, free admission to the park but some attractions have fees. Accessible by MUNI. Bring a picnic blanket and snacks for a romantic afternoon."
  },
  {
    name: "Palace of Fine Arts",
    address: "3601 Lyon St, San Francisco, CA",
    locationDescription: "Historic structure and lagoon in the Marina District",
    highlights: [
      "Stunning Beaux-Arts architecture creates perfect photo opportunities",
      "Peaceful lagoon with swans adds to the romantic atmosphere",
      "Beautiful lighting at sunset and evening hours"
    ],
    hours: "Open 24 hours (exterior grounds)",
    specialNotes: "Outdoor venue, free admission. Easily accessible by public transportation. Popular wedding and photoshoot location, particularly stunning at sunset."
  },
  {
    name: "Lands End",
    address: "680 Point Lobos Ave, San Francisco, CA",
    locationDescription: "Scenic coastal trail in the northwest corner of San Francisco",
    highlights: [
      "Breathtaking views of the Golden Gate Bridge",
      "Hidden Labyrinth art installation offers a magical experience",
      "Dramatic ocean cliffs and beautiful sunsets"
    ],
    hours: "Sunrise to sunset daily",
    specialNotes: "Outdoor venue, free admission. Limited parking available. Wear comfortable walking shoes as trails can be uneven. Pack layers as it can be windy."
  },
  {
    name: "Foreign Cinema",
    address: "2534 Mission St, San Francisco, CA",
    locationDescription: "Iconic restaurant and cinema in the Mission District",
    highlights: [
      "Dine under the stars while classic films play on the wall",
      "Award-winning California-Mediterranean cuisine",
      "Romantic ambiance with string lights and a sophisticated setting"
    ],
    hours: "Dinner: 5:00 PM-10:00 PM (11:00 PM Fri-Sat), Brunch: 11:00 AM-3:00 PM Sat-Sun",
    specialNotes: "Indoor/outdoor seating available. $$$ price range. Reservations strongly recommended. Near 24th St BART station. Perfect for dinner and entertainment in one location."
  },
  {
    name: "Twin Peaks",
    address: "501 Twin Peaks Blvd, San Francisco, CA",
    locationDescription: "Famous scenic viewpoint in central San Francisco",
    highlights: [
      "Panoramic 360-degree views of the entire city and bay",
      "Spectacular spot for watching sunrise or sunset with your partner",
      "One of the highest points in San Francisco offering unparalleled vistas"
    ],
    hours: "Open 24 hours, parking area closes at midnight",
    specialNotes: "Outdoor venue, free admission. Can be reached by car or the 37 Corbett bus followed by a short walk. Very windy and can be cold - bring warm clothes. Popular for proposals and romantic moments."
  },
  {
    name: "California Academy of Sciences",
    address: "55 Music Concourse Dr, San Francisco, CA",
    locationDescription: "Natural history museum in Golden Gate Park",
    highlights: [
      "NightLife events every Thursday for 21+ with cocktails and music",
      "Romantic strolls through the four-story rainforest dome",
      "Stargazing opportunities at the Morrison Planetarium"
    ],
    hours: "Mon-Sat: 9:30 AM-5:00 PM, Sun: 11:00 AM-5:00 PM, NightLife Thurs: 6:00 PM-10:00 PM",
    specialNotes: "Indoor venue, admission: $35-40, NightLife: $15-20. Located in Golden Gate Park, accessible by MUNI. Thursday NightLife events are perfect for date night with specialty cocktails and changing themes."
  },
  {
    name: "Baker Beach",
    address: "1504 Pershing Dr, San Francisco, CA",
    locationDescription: "Scenic beach with Golden Gate Bridge views in the Presidio",
    highlights: [
      "Stunning views of the Golden Gate Bridge and Marin Headlands",
      "Less crowded than other beaches for more intimate experiences",
      "Beautiful spot for sunset watching and long walks"
    ],
    hours: "Sunrise to sunset daily",
    specialNotes: "Outdoor venue, free admission. Limited parking available. The north end is clothing-optional. Accessible by MUNI 29 bus. Strong currents make swimming unsafe, but perfect for romantic shoreline walks. Bring a blanket and picnic."
  },
  {
    name: "de Young Museum",
    address: "50 Hagiwara Tea Garden Dr, San Francisco, CA",
    locationDescription: "Fine arts museum in Golden Gate Park",
    highlights: [
      "Observation tower offers panoramic city views for free",
      "World-class art collections spanning multiple cultures and eras",
      "Sculpture garden perfect for reflective walks together"
    ],
    hours: "Tues-Sun: 9:30 AM-5:15 PM, Closed Mondays",
    specialNotes: "Indoor venue, admission: $15 (tower access is free). Located in Golden Gate Park, accessible by MUNI. Friday Nights at the de Young feature live music, dancing, and specialty cocktails (seasonal program)."
  },
  {
    name: "Alamo Square Park",
    address: "Hayes & Steiner St, San Francisco, CA",
    locationDescription: "Iconic park in Western Addition featuring Painted Ladies",
    highlights: [
      "Famous view of Victorian 'Painted Ladies' against downtown skyline",
      "Grassy hills perfect for picnics with a view",
      "Beautiful spot for watching sunset over the city"
    ],
    hours: "5:00 AM to midnight daily",
    specialNotes: "Outdoor venue, free admission. Accessible by several MUNI lines. Popular tourist spot but large enough to find a private area. Bring a blanket and picnic. Bathroom facilities available."
  },
  {
    name: "Exploratorium",
    address: "Pier 15 Embarcadero at Green St, San Francisco, CA",
    locationDescription: "Interactive science museum on the Embarcadero",
    highlights: [
      "Adults-only After Dark Thursday evenings with cocktails and special exhibits",
      "Tactile Dome offers unique sensory experience in complete darkness",
      "Outdoor installations with stunning waterfront views"
    ],
    hours: "Tues-Sun: 10:00 AM-5:00 PM, Thu (18+): 6:00 PM-10:00 PM, Closed Mondays",
    specialNotes: "Indoor/outdoor venue, admission: $30-40, After Dark: $20. Easily accessible via Embarcadero MUNI/BART. Thursday night Adult-only events make for a perfect interactive date night with a bar and changing themes."
  },
  {
    name: "Waterbar",
    address: "399 The Embarcadero, San Francisco, CA",
    locationDescription: "Upscale seafood restaurant on the Embarcadero waterfront",
    highlights: [
      "Panoramic views of the Bay Bridge, especially beautiful when illuminated at night",
      "Extensive oyster selection with daily happy hour specials",
      "Sophisticated atmosphere with floor-to-ceiling aquariums"
    ],
    hours: "Lunch: 11:30 AM-2:30 PM, Dinner: 5:30 PM-9:30 PM daily",
    specialNotes: "Indoor/outdoor seating available. $$$$ price range. Reservations recommended. Easily accessible via Embarcadero MUNI/BART. Request a window or patio table for the best views. Dress smart casual."
  },
  {
    name: "Crissy Field",
    address: "1199 E Beach, San Francisco, CA",
    locationDescription: "Scenic waterfront park in the Presidio",
    highlights: [
      "Picturesque walking path along the bay with Golden Gate Bridge views",
      "Protected beach area perfect for romantic strolls",
      "Abundant wildlife viewing opportunities in restored wetlands"
    ],
    hours: "Open 24 hours daily",
    specialNotes: "Outdoor venue, free admission. Limited parking available. Accessible by MUNI 28 or 30 buses. Often windy - bring layers. The Warming Hut cafe offers coffee and snacks. Great for active couples who enjoy walking, biking, or running."
  },
  {
    name: "Bourbon & Branch",
    address: "501 Jones St, San Francisco, CA",
    locationDescription: "Speakeasy-style cocktail bar in the Tenderloin",
    highlights: [
      "Authentic 1920s speakeasy atmosphere with hidden rooms and secret passages",
      "Expert mixologists craft exquisite custom cocktails",
      "Intimate lighting and plush seating create a cozy, romantic ambiance"
    ],
    hours: "6:00 PM-2:00 AM daily",
    specialNotes: "Indoor venue, $$ price range for drinks. Reservations required with password for entry. Near Powell BART station. No photos allowed inside to maintain the exclusive atmosphere. Strict rules enhance the speakeasy experience."
  },
  {
    name: "Bernal Heights Park",
    address: "Bernal Heights Blvd, San Francisco, CA",
    locationDescription: "Hilltop park in Bernal Heights neighborhood",
    highlights: [
      "Stunning 360-degree panoramic views of the city",
      "Less crowded alternative to Twin Peaks for intimate moments",
      "Dog-friendly atmosphere for couples with pets"
    ],
    hours: "Sunrise to sunset daily",
    specialNotes: "Outdoor venue, free admission. Limited street parking. Accessible by MUNI 67 bus. Short but steep hike to the top - wear comfortable shoes. Bring a blanket to sit and enjoy the views. Especially beautiful at sunset."
  },
  {
    name: "Japanese Tea Garden",
    address: "75 Hagiwara Tea Garden Dr, San Francisco, CA",
    locationDescription: "Historic Japanese garden in Golden Gate Park",
    highlights: [
      "Tranquil setting with authentic Japanese landscaping, pagodas, and bridges",
      "Tea house offering traditional Japanese tea service",
      "Seasonal flower displays including cherry blossoms in spring"
    ],
    hours: "Summer (Mar-Oct): 9:00 AM-5:45 PM, Winter (Nov-Feb): 9:00 AM-4:45 PM",
    specialNotes: "Outdoor venue, admission: ~$15 (free for SF residents). Located in Golden Gate Park, accessible by MUNI. Peaceful environment perfect for quiet conversation. Can get crowded with tourists, early morning or weekday visits recommended."
  },
  {
    name: "Smuggler's Cove",
    address: "650 Gough St, San Francisco, CA",
    locationDescription: "Tiki bar in Hayes Valley",
    highlights: [
      "Immersive three-level pirate ship decor with waterfalls and nautical elements",
      "World-class rum selection with over 400 varieties",
      "Intimate nooks and crannies for private conversation"
    ],
    hours: "5:00 PM-1:15 AM daily",
    specialNotes: "Indoor venue, $$ price range for drinks. Small venue that fills up quickly. No reservations. Near Van Ness MUNI station. Dimly lit interior perfect for cozy dates. Known for authentic, strong tiki drinks served in elaborate glassware."
  },
  {
    name: "Sutro Baths",
    address: "1004 Point Lobos Ave, San Francisco, CA",
    locationDescription: "Historic bath house ruins on the western shoreline",
    highlights: [
      "Atmospheric ruins with dramatic ocean backdrop",
      "Magical spot at sunset when the ruins are silhouetted",
      "Connected to scenic coastal trails for extended exploring"
    ],
    hours: "Sunrise to sunset daily",
    specialNotes: "Outdoor venue, free admission. Parking available at the Cliff House lot or along Point Lobos Ave. Accessible by MUNI 38 bus. Can be foggy and windy - bring layers. Explore the nearby Cave for added adventure, but check tide schedules for safety."
  },
  {
    name: "The Garden at AT&T Park",
    address: "24 Willie Mays Plaza, San Francisco, CA",
    locationDescription: "Social garden space behind the centerfield wall at Oracle Park",
    highlights: [
      "Unique baseball viewing experience with garden setting",
      "Fresh food grown on-site used in stadium concessions",
      "Beautiful bay views beyond the outfield"
    ],
    hours: "Only during San Francisco Giants home games",
    specialNotes: "Outdoor venue, requires game ticket purchase (varies). Easily accessible via MUNI or BART to Oracle Park. Perfect for dates with baseball fans. Food and drinks available for purchase. Find a spot by the railing for the best views of both the game and bay."
  },
  {
    name: "Urban Putt",
    address: "1096 South Van Ness Ave, San Francisco, CA",
    locationDescription: "Indoor miniature golf course in the Mission District",
    highlights: [
      "Inventive, San Francisco-themed mini-golf course with interactive elements",
      "Full restaurant and bar for dinner before or after golfing",
      "Whimsical atmosphere perfect for playful dates"
    ],
    hours: "Mon-Thu: 4:00 PM-12:00 AM, Fri: 4:00 PM-2:00 AM, Sat: 11:00 AM-2:00 AM, Sun: 11:00 AM-12:00 AM",
    specialNotes: "Indoor venue, Mini-golf: $16 per person, plus food/drinks: $$. Near 24th St BART station. Good for playful, interactive dates. Can get crowded on weekends - weekday evenings recommended. Restaurant upstairs serves comfort food and craft cocktails."
  },
  {
    name: "Salesforce Park",
    address: "425 Mission St, San Francisco, CA",
    locationDescription: "Elevated park above the Salesforce Transit Center downtown",
    highlights: [
      "Urban oasis with botanical garden representing 13 different ecosystems",
      "Outdoor amphitheater with regular cultural performances and music",
      "Walking path with stunning views of the city skyline"
    ],
    hours: "6:00 AM-9:00 PM daily",
    specialNotes: "Outdoor venue, free admission. Easily accessible via MUNI or BART to Salesforce Transit Center. Food trucks and cafe on-site. Guided garden tours available. Pop-up events including yoga classes. Check calendar for special evening events."
  },
  {
    name: "Emporium Arcade Bar",
    address: "616 Divisadero St, San Francisco, CA",
    locationDescription: "Vintage arcade and bar in NoPa",
    highlights: [
      "Classic arcade games and pinball machines for playful competition",
      "Live music and DJs on weekends create a lively atmosphere",
      "Pool tables and shuffleboard for interactive entertainment"
    ],
    hours: "Mon-Thu: 4:00 PM-2:00 AM, Fri: 3:00 PM-2:00 AM, Sat-Sun: 2:00 PM-2:00 AM",
    specialNotes: "Indoor venue, no cover charge, games cost tokens: $. Drinks: $$. Near MUNI 24 bus line. Perfect for casual, active dates. Can get crowded on weekend evenings. 21+ venue. Most games cost 50¢-$1 in tokens."
  },
  {
    name: "Stow Lake",
    address: "50 Stow Lake Dr, San Francisco, CA",
    locationDescription: "Scenic lake in Golden Gate Park",
    highlights: [
      "Rowboat and pedal boat rentals for romantic water experiences",
      "Chinese Pavilion offers a perfect spot for romantic photos",
      "Historic boathouse with cafe for post-paddling refreshments"
    ],
    hours: "Boat rental: 10:00 AM-6:00 PM daily",
    specialNotes: "Outdoor venue, free admission to lake area, boat rentals: $23-38/hour. Located in Golden Gate Park, accessible by MUNI. Circle the entire lake by foot on a 1-mile trail if boating isn't your style. Feed the ducks and watch for turtles sunning themselves on logs."
  },
  {
    name: "Archimedes Banya",
    address: "748 Innes Ave, San Francisco, CA",
    locationDescription: "Russian bathhouse in Hunters Point",
    highlights: [
      "Authentic Russian sauna and bath experience with multiple thermal rooms",
      "Rooftop lounge with city views and cold plunge pools",
      "Social atmosphere with cafe serving Eastern European food and drinks"
    ],
    hours: "Mon-Fri: 12:00 PM-12:00 AM, Sat-Sun: 10:00 AM-12:00 AM",
    specialNotes: "Indoor venue, admission: $55+ for 3 hours. Located in Hunters Point, limited public transit options - car recommended. Clothing optional in same-sex areas, swimsuits required in co-ed areas. Bring swimsuit, flip flops, and a couple of towels. Unique, adventurous date idea."
  },
  {
    name: "Press Club",
    address: "20 Yerba Buena Ln, San Francisco, CA",
    locationDescription: "Upscale wine bar in SoMa/Union Square",
    highlights: [
      "Sophisticated underground wine lounge with elegant atmosphere",
      "Rotating selection of boutique California wineries",
      "Artisan cheese and charcuterie pairings with knowledgeable staff"
    ],
    hours: "Tue-Thu: 4:00 PM-10:00 PM, Fri-Sat: 4:00 PM-11:00 PM, Closed Sun-Mon",
    specialNotes: "Indoor venue, wine flights: $$-$$$. Near Powell BART/MUNI station. Ideal for wine enthusiasts. Elegant atmosphere with comfortable seating for intimate conversation. No reservations needed except for large groups."
  },
  {
    name: "Mission Dolores Park",
    address: "Dolores St & 19th St, San Francisco, CA",
    locationDescription: "Popular urban park in the Mission District",
    highlights: [
      "Stunning views of downtown San Francisco skyline",
      "Lively, social atmosphere perfect for people-watching",
      "Sunny microclimate makes it warmer than most parts of the city"
    ],
    hours: "6:00 AM to 10:00 PM daily",
    specialNotes: "Outdoor venue, free admission. Near Mission Dolores Park MUNI stop. Extremely popular on sunny weekends. Bring a blanket, picnic, and beverages. Unofficially divided into areas (families, dog area, hipster hill, gay beach). Bathroom facilities available."
  },
  {
    name: "Greens Restaurant",
    address: "Building A, Fort Mason Center, 2 Marina Blvd, San Francisco, CA",
    locationDescription: "Vegetarian restaurant in the Marina with water views",
    highlights: [
      "Floor-to-ceiling windows with spectacular views of the Golden Gate Bridge",
      "Pioneer of farm-to-table vegetarian cuisine in a converted warehouse space",
      "Peaceful atmosphere in historic Fort Mason Center"
    ],
    hours: "Lunch: Tue-Fri 11:30 AM-2:30 PM, Dinner: Tue-Sun 5:30 PM-9:00 PM, Brunch: Sat-Sun 10:30 AM-2:30 PM",
    specialNotes: "Indoor venue, $$$ price range. Parking available at Fort Mason. Accessible by MUNI 28 or 30 buses. Reservations recommended. Request a window seat for the best views. Great for vegetarian couples or those looking for healthy, creative cuisine."
  },
  {
    name: "Marrakech Magic Theater",
    address: "419 O'Farrell St, San Francisco, CA",
    locationDescription: "Intimate magic theater in Union Square/Tenderloin",
    highlights: [
      "Close-up magic show in a small, intimate 45-seat theater",
      "Interactive performance with audience participation",
      "Pre-show reception with the magician in the Moroccan-styled parlor"
    ],
    hours: "Shows at 6:30 PM and 9:00 PM, Thu-Sun",
    specialNotes: "Indoor venue, tickets: $45-75. Near Powell BART station. Arrive 30 minutes early for the pre-show reception. Moroccan-inspired decor sets a romantic mood. Drinks available for purchase. Shows sell out - advance tickets recommended."
  },
  {
    name: "Conservatory of Flowers",
    address: "100 John F Kennedy Dr, San Francisco, CA",
    locationDescription: "Victorian greenhouse in Golden Gate Park",
    highlights: [
      "Stunning white Victorian architecture houses rare tropical plants",
      "Five distinct galleries with different climate zones to explore",
      "Special exhibits and seasonal displays throughout the year"
    ],
    hours: "Tue-Sun: 10:00 AM-4:30 PM, Closed Mondays",
    specialNotes: "Indoor venue, admission: $10. Located in Golden Gate Park, accessible by MUNI. Warm and humid environment inside - dress accordingly. Special evening events with colorful light projections occur seasonally. Perfect for plant lovers and Victorian architecture enthusiasts."
  },
  {
    name: "Posh and Ponder Pottery",
    address: "1824 Irving St, San Francisco, CA",
    locationDescription: "Pottery studio in the Sunset District",
    highlights: [
      "Hands-on pottery painting for a creative, interactive date",
      "BYOB policy allows you to enjoy wine while creating",
      "Make lasting keepsakes to remember your date"
    ],
    hours: "Wed-Sun: 11:00 AM-7:00 PM, Closed Mon-Tue",
    specialNotes: "Indoor venue, pottery pieces: $16-60 plus studio fee: $15. Located in the Sunset, accessible by N-Judah MUNI line. No experience necessary. Pieces need to be fired and will be ready for pickup in about a week. Reservations recommended on weekends."
  },
  {
    name: "Angel Island",
    address: "1 Ferry Building, San Francisco, CA (Ferry from SF)",
    locationDescription: "State park island in the San Francisco Bay",
    highlights: [
      "360-degree views of the San Francisco Bay, skyline, Golden Gate and Bay Bridges",
      "Historic sites including immigration station and military installations",
      "Miles of hiking and biking trails with romantic picnic spots"
    ],
    hours: "Ferry schedule varies by season, island open 8:00 AM-sunset",
    specialNotes: "Outdoor venue, ferry ticket: $14-30 round trip. Ferry from SF Ferry Building or Pier 41. Full-day excursion recommended. Bring water, food, and sun protection. Bike rentals available on the island. Check ferry schedule carefully as last return ferries are often early."
  },
  {
    name: "Walt Disney Family Museum",
    address: "104 Montgomery St, San Francisco, CA",
    locationDescription: "Disney history museum in the Presidio",
    highlights: [
      "Fascinating look at Walt Disney's life and creative process",
      "Interactive exhibits and early animation artifacts",
      "Beautiful location in historic Presidio buildings with Bay views"
    ],
    hours: "Thu-Sun: 10:00 AM-5:00 PM, Closed Mon-Wed",
    specialNotes: "Indoor venue, admission: $25. Located in the Presidio, limited public transit - PresidiGo shuttle available. Perfect for Disney fans or animation enthusiasts. Special exhibitions rotate throughout the year. Museum cafe available for refreshments."
  },
  {
    name: "Lyon Street Steps",
    address: "Lyon St & Broadway St, San Francisco, CA",
    locationDescription: "Scenic staircase in Pacific Heights",
    highlights: [
      "Breathtaking views of the Palace of Fine Arts and the Bay",
      "Beautifully landscaped stairway with flowers and greenery",
      "Heart-shaped bushes at the top make for romantic photo opportunities"
    ],
    hours: "Open 24 hours daily",
    specialNotes: "Outdoor venue, free admission. Limited street parking. Moderately strenuous climb of 288 steps total. Popular with fitness enthusiasts. Best at sunrise or sunset. Located in an upscale residential neighborhood - please be respectful of noise levels."
  },
  {
    name: "The Castro Theatre",
    address: "429 Castro St, San Francisco, CA",
    locationDescription: "Historic movie palace in the Castro District",
    highlights: [
      "Grand 1920s movie palace with ornate interior and Wurlitzer organ",
      "Sing-along events and classic film screenings create unique experiences",
      "Pre-show organ performances set a magical atmosphere"
    ],
    hours: "Varies by showtimes, typically opens 30 minutes before first screening",
    specialNotes: "Indoor venue, tickets: $11-18. Near Castro MUNI station. Check calendar for special events like sing-alongs and film festivals. Arrive early to hear the organ performance before the film. Balcony seating recommended for couples. Concessions available."
  },
  {
    name: "SF Symphony - Davies Symphony Hall",
    address: "201 Van Ness Ave, San Francisco, CA",
    locationDescription: "World-class symphony hall in Civic Center",
    highlights: [
      "Exceptional acoustics and performances by renowned musicians",
      "Elegant, romantic atmosphere with pre-show dining options",
      "Special themed concerts and film score performances"
    ],
    hours: "Performance dependent, box office: Mon-Fri 10:00 AM-6:00 PM, Sat 12:00 PM-6:00 PM",
    specialNotes: "Indoor venue, tickets: $$-$$$$. Near Civic Center BART/MUNI. Dress code varies by performance. Arrive early for pre-concert talks. SoundBox series offers more casual, experimental performances in a lounge setting for younger audiences."
  },
  {
    name: "Corona Heights Park",
    address: "Roosevelt Way & Museum Way, San Francisco, CA",
    locationDescription: "Rocky outcrop park with panoramic views",
    highlights: [
      "Less crowded alternative to more famous viewpoints",
      "Dramatic red rock formations create a striking landscape",
      "Spectacular sunsets over the city with minimal crowds"
    ],
    hours: "5:00 AM to midnight daily",
    specialNotes: "Outdoor venue, free admission. Limited street parking. Accessible by MUNI 24 or N-Judah + short walk. Short but steep climb to summit - wear appropriate footwear. Dog-friendly. Tennis courts and playground at base if you want to get playful."
  },
  {
    name: "Alcatraz Island",
    address: "Pier 33 Alcatraz Landing, San Francisco, CA",
    locationDescription: "Historic federal prison on an island in the Bay",
    highlights: [
      "Fascinating guided tours of the infamous prison",
      "Beautiful bay views and ferry ride experience",
      "Night tours create a particularly atmospheric experience"
    ],
    hours: "Ferry departures 8:45 AM-3:50 PM, varies by season",
    specialNotes: "Outdoor/indoor venue, tickets: $41-50. Ferries depart from Pier 33. Book tickets well in advance (2-3 weeks minimum, especially for night tours). Dress warmly as it's cooler on the island and ferry. Trip takes 2.5-3 hours total. Night tours offer a more intimate, eerie experience."
  },
  {
    name: "Tonga Room & Hurricane Bar",
    address: "950 Mason St, San Francisco, CA (in the Fairmont Hotel)",
    locationDescription: "Historic tiki bar in Nob Hill",
    highlights: [
      "Iconic swimming pool converted to a lagoon with periodic 'rainstorms'",
      "Live music performed on a floating stage",
      "Vintage Polynesian atmosphere with strong tropical drinks"
    ],
    hours: "Wed-Thu: 5:00 PM-11:30 PM, Fri-Sat: 5:00 PM-12:30 AM, Sun: 5:00 PM-11:30 PM",
    specialNotes: "Indoor venue, cover charge may apply for certain hours, drinks: $$$. Near California St cable car. Happy hour Wed-Fri 5:00 PM-7:00 PM. Reservations recommended. Dress code enforced (smart casual). One of the oldest continuously operating tiki bars in the U.S."
  },
  {
    name: "Belden Place",
    address: "Belden Pl, San Francisco, CA",
    locationDescription: "European-style alley with outdoor dining in Financial District",
    highlights: [
      "Romantic alfresco dining reminiscent of European streets",
      "Multiple restaurants offering French, Italian, and Mediterranean cuisine",
      "Festive string lights create an enchanting atmosphere"
    ],
    hours: "Varies by restaurant, typically 11:00 AM-10:00 PM",
    specialNotes: "Outdoor venue, meal prices: $$-$$$. Near Montgomery BART station. Particularly lovely in the evening when lit up. Reservations recommended for dinner. Multiple restaurant options in one location make it perfect for couples who can't agree on cuisine."
  },
  {
    name: "San Francisco Botanical Garden",
    address: "1199 9th Ave, San Francisco, CA",
    locationDescription: "Expansive botanical garden in Golden Gate Park",
    highlights: [
      "55 acres featuring over 8,000 plant species from around the world",
      "Secluded groves and intimate garden spaces perfect for quiet moments",
      "Seasonal highlights include magnolia collection and cloud forest"
    ],
    hours: "7:30 AM-6:00 PM (Summer), 7:30 AM-5:00 PM (Winter)",
    specialNotes: "Outdoor venue, admission: $10 (free to SF residents). Located in Golden Gate Park, accessible by MUNI. Free guided tours available. Flower Piano event in July features publicly playable pianos throughout the garden. Bring a plant guide to learn together."
  },
  {
    name: "Seward Street Slides",
    address: "Seward St & Douglas St, San Francisco, CA",
    locationDescription: "Hidden concrete slides in Castro/Noe Valley",
    highlights: [
      "Playful, hidden gem perfect for couples who embrace their inner child",
      "Thrilling concrete slides created by a local resident",
      "Secluded park setting with views of the surrounding neighborhoods"
    ],
    hours: "Sunrise to sunset daily",
    specialNotes: "Outdoor venue, free admission. Limited street parking. Bring cardboard to sit on for sliding (sometimes available on-site). Accessible via 24 bus or J-Church MUNI + uphill walk. Not well-known to tourists. Ideal for playful, adventurous couples."
  },
  {
    name: "Garden Creamery",
    address: "3566 20th St, San Francisco, CA",
    locationDescription: "Artisanal ice cream shop in the Mission",
    highlights: [
      "Unique dairy and vegan ice cream flavors with Asian-inspired options",
      "Instagram-worthy 'chimney cones' - donut-like pastry filled with ice cream",
      "Small-batch flavors change regularly for new experiences"
    ],
    hours: "Mon-Thu: 1:00 PM-9:00 PM, Fri-Sun: 12:00 PM-10:00 PM",
    specialNotes: "Indoor venue, $ price range. Near 16th St BART station + short walk. Often has a line on weekends. Try their signature 'Matchadoodle' flavor. Small seating area. Located near Dolores Park for a nice stroll with your treats."
  },
  {
    name: "The Interval at Long Now",
    address: "2 Marina Blvd, Building A, San Francisco, CA",
    locationDescription: "Unique cocktail bar and museum in Fort Mason",
    highlights: [
      "Fascinating combination of bar, library, and museum in one space",
      "Thoughtfully crafted cocktails with scientific and historical context",
      "Intriguing mechanical artifacts including a 10,000 Year Clock prototype"
    ],
    hours: "Wed-Fri: 4:00 PM-10:00 PM, Sat-Sun: 12:00 PM-10:00 PM",
    specialNotes: "Indoor venue, drinks: $$. Located in Fort Mason, accessible by MUNI 28 or 30 buses. Perfect for intellectual conversations. Tea service available during the day. Small plates menu available. Belongs to the Long Now Foundation, dedicated to long-term thinking."
  },
  {
    name: "Nightbird Restaurant",
    address: "330 Gough St, San Francisco, CA",
    locationDescription: "Fine dining restaurant in Hayes Valley",
    highlights: [
      "Intimate space with only 38 seats for a private dining experience",
      "Five-course tasting menu showcasing seasonal California ingredients",
      "Adjacent Linden Room cocktail bar offers pre or post-dinner drinks"
    ],
    hours: "Tue-Sat: 5:00 PM-10:00 PM, Closed Sun-Mon",
    specialNotes: "Indoor venue, fixed-price menu: $$$$. Near Van Ness MUNI station. Reservations essential, book 2-4 weeks in advance. Chef Kim Alter changes the menu frequently to showcase the best seasonal ingredients. Perfect for special occasions."
  },
  {
    name: "Fort Point",
    address: "Long Ave & Marine Dr, San Francisco, CA",
    locationDescription: "Civil War era fort beneath the Golden Gate Bridge",
    highlights: [
      "Unique perspective of the Golden Gate Bridge from directly underneath",
      "Fascinating Civil War history with ranger-led tours",
      "Multiple levels to explore with atmospheric brick architecture"
    ],
    hours: "Fri-Sun: 10:00 AM-5:00 PM",
    specialNotes: "Indoor/outdoor venue, free admission. Limited parking. Accessible by MUNI 28 bus. Can be windy and cold - dress in layers. Check schedule for cannon demonstrations and ranger talks. Great combination of history and spectacular views."
  },
  {
    name: "The Speakeasy",
    address: "Location revealed upon ticket purchase, North Beach area",
    locationDescription: "Immersive theater experience in a secret location",
    highlights: [
      "Step back in time to a Prohibition-era speakeasy with interactive theater",
      "Live jazz, cabaret performances, and gambling rooms to explore",
      "Elaborate 1920s setting with actors who interact with guests"
    ],
    hours: "Show times vary, typically 7:30 PM-11:00 PM on Fri-Sat",
    specialNotes: "Indoor venue, tickets: $80-125. Period attire encouraged but not required. Exact location revealed after ticket purchase (North Beach area). Interactive experience where you can follow different characters and storylines. Drinks available for purchase. Must be 21+."
  },
  {
    name: "Balmy Alley Murals",
    address: "Balmy St, between 24th St & 25th St, San Francisco, CA",
    locationDescription: "Mural-filled alley in the Mission District",
    highlights: [
      "Highest concentration of murals in the city dating back to the 1980s",
      "Powerful political and cultural artwork representing Latino culture",
      "Constantly evolving with new murals added regularly"
    ],
    hours: "Open 24 hours daily (daylight hours recommended)",
    specialNotes: "Outdoor venue, free admission. Near 24th St BART station. Consider taking a guided tour to learn about the history and meaning of the murals. Respectful photography encouraged. Combine with other Mission food and cultural spots for a full day experience."
  },
  {
    name: "Musée Mécanique",
    address: "Pier 45, Fisherman's Wharf, San Francisco, CA",
    locationDescription: "Antique arcade museum at Fisherman's Wharf",
    highlights: [
      "One of the world's largest collections of coin-operated mechanical musical instruments",
      "Nostalgic arcade games and amusements from the early 20th century",
      "Interactive experiences from fortune tellers to love testers"
    ],
    hours: "10:00 AM-8:00 PM daily",
    specialNotes: "Indoor venue, free admission, games: $0.25-$1 each. Located at Fisherman's Wharf, easily accessible by F-Market streetcar. Bring quarters (change machines available). Nostalgic, playful environment perfect for lighthearted dates. Most machines are originals, not reproductions."
  },
  {
    name: "Stern Grove",
    address: "19th Ave & Sloat Blvd, San Francisco, CA",
    locationDescription: "Natural amphitheater and park in the Sunset District",
    highlights: [
      "Famous free summer concert series in a beautiful outdoor setting",
      "Secluded eucalyptus-lined paths perfect for romantic walks",
      "Natural ravine with lush vegetation creates intimate spaces"
    ],
    hours: "6:00 AM to 10:00 PM daily, concert series Sundays at 2:00 PM (June-August)",
    specialNotes: "Outdoor venue, free admission. Limited parking. Accessible by MUNI L-Taraval line. During summer concert series, arrive early (12 PM or earlier) to get good spots. Bring blankets, layers, and a picnic. Dogs allowed on leash. Outside of concert season, enjoy the peaceful walking paths."
  },
  {
    name: "War Memorial Opera House",
    address: "301 Van Ness Ave, San Francisco, CA",
    locationDescription: "Historic opera venue in Civic Center",
    highlights: [
      "Stunning Beaux-Arts architecture dating from 1932",
      "World-class opera and ballet performances",
      "Opulent interior with grand staircases and chandeliers"
    ],
    hours: "Performance dependent, box office: Mon-Fri 10:00 AM-6:00 PM",
    specialNotes: "Indoor venue, tickets: $$-$$$$. Near Civic Center BART/MUNI. Semi-formal to formal attire recommended. Rush tickets sometimes available for younger patrons. Tours of the building available on select dates. Pre-performance talks offered 55 minutes before curtain."
  },
  {
    name: "Tartine Manufactory",
    address: "595 Alabama St, San Francisco, CA",
    locationDescription: "Artisanal bakery and restaurant in the Mission District",
    highlights: [
      "World-famous baked goods in an airy, industrial-chic space",
      "Open kitchen where you can watch master bakers at work",
      "All-day dining options from breakfast pastries to dinner"
    ],
    hours: "8:00 AM-9:00 PM daily",
    specialNotes: "Indoor venue, $$ price range. Near 16th St BART station + short walk. Often has lines, especially on weekends. Grab fresh bread and pastries to enjoy at nearby Dolores Park. Coffee program is excellent. Seasonal menu highlights local producers."
  },
  {
    name: "Legion of Honor",
    address: "100 34th Ave, San Francisco, CA",
    locationDescription: "Fine arts museum in Lincoln Park",
    highlights: [
      "Neoclassical building with panoramic views of the Golden Gate Bridge",
      "European masterpieces from antiquity to the 20th century",
      "Tranquil courtyard featuring Rodin's 'The Thinker' sculpture"
    ],
    hours: "Tue-Sun: 9:30 AM-5:15 PM, Closed Mondays",
    specialNotes: "Indoor venue, admission: $15 (free first Tuesday of month). Limited public transit access - car or rideshare recommended. Combined ticket available with de Young Museum. Classical concerts on select Saturdays. Holocaust Memorial sculpture garden outside is powerful and moving."
  },
  {
    name: "Filoli Historic House & Garden",
    address: "86 Cañada Road, Woodside, CA",
    locationDescription: "Historic estate and gardens just south of San Francisco",
    highlights: [
      "16 acres of formal gardens inspired by English country estates",
      "Georgian-style mansion with period furnishings and art",
      "Seasonal displays including spectacular spring blooms and holiday decorations"
    ],
    hours: "10:00 AM-5:00 PM daily (closed Mondays in winter)",
    specialNotes: "Indoor/outdoor venue, admission: $25. Located 30 minutes south of SF - car required. Special evening events during summer and holiday seasons. Cafe on premises for refreshments. Self-guided tours of the house and gardens. Featured in the opening credits of 'Dynasty' TV show."
  },
  {
    name: "Yerba Buena Gardens",
    address: "750 Howard St, San Francisco, CA",
    locationDescription: "Urban park and cultural complex in SoMa",
    highlights: [
      "Peaceful gardens and waterfall in the midst of downtown",
      "Summer outdoor performance series featuring diverse artists",
      "Martin Luther King Jr. Memorial and public art installations"
    ],
    hours: "6:00 AM-10:00 PM daily",
    specialNotes: "Outdoor venue, free admission. Near Powell BART/MUNI station. Surrounded by cultural institutions including SFMOMA, Contemporary Jewish Museum, and Yerba Buena Center for the Arts. Carousel and ice skating rink adjacent. Many dining options nearby. Free WiFi throughout."
  },
  {
    name: "Mission Cliffs Climbing Gym",
    address: "2295 Harrison St, San Francisco, CA",
    locationDescription: "Indoor rock climbing gym in the Mission District",
    highlights: [
      "45-foot walls with routes for all skill levels",
      "Day passes available with gear rental for spontaneous dates",
      "Supportive environment perfect for trying something new together"
    ],
    hours: "Mon-Fri: 6:00 AM-11:00 PM, Sat-Sun: 8:00 AM-8:00 PM",
    specialNotes: "Indoor venue, day pass: $32 (includes equipment rental). Near 16th St BART station. Introductory belay lessons available for beginners. Athletic clothing recommended. Excellent active date idea that builds trust. No experience necessary for bouldering area."
  },
  {
    name: "Ferry Building Marketplace",
    address: "1 Ferry Building, San Francisco, CA",
    locationDescription: "Historic ferry terminal and gourmet marketplace on the Embarcadero",
    highlights: [
      "Artisanal food vendors and locally-sourced products",
      "Waterfront location with views of the Bay Bridge",
      "Saturday farmers market brings additional vendors and energy"
    ],
    hours: "10:00 AM-7:00 PM daily, individual vendor hours vary",
    specialNotes: "Indoor venue, various price points for food and shopping. Easily accessible via Ferry Building MUNI/BART. Popular spots include Hog Island Oyster Co., Acme Bread, and Cowgirl Creamery. Ferry service to various Bay destinations available. Tuesday, Thursday, and Saturday farmers markets."
  },
  {
    name: "16th Avenue Tiled Steps",
    address: "16th Ave & Moraga St, San Francisco, CA",
    locationDescription: "Mosaic staircase in the Sunset District",
    highlights: [
      "Stunning sea-to-stars themed mosaic spanning 163 steps",
      "Panoramic views of the city from the top",
      "Hidden gem off the typical tourist path"
    ],
    hours: "Open 24 hours daily (daylight hours recommended)",
    specialNotes: "Outdoor venue, free admission. Limited street parking. Accessible by MUNI N-Judah + short walk. Continue up to Grand View Park for even better views. Hidden Garden Steps nearby at 16th and Kirkham offers a second mosaic staircase. Quiet residential area - be respectful."
  }
];

export default sfDateSpots; 