import React, { useState } from 'react';
import { db } from '../firebase';
import { collection, addDoc } from 'firebase/firestore';
import './Survey.css';

const Survey = ({ onClose, className }) => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answers, setAnswers] = useState({});
  const [showThankYou, setShowThankYou] = useState(false);
  const [selectedPlaces, setSelectedPlaces] = useState([]);
  const [selectedPriorities, setSelectedPriorities] = useState([]);
  const [currentInput, setCurrentInput] = useState('');

  const questions = [
    {
      id: 'gender',
      question: "I am",
      type: 'single',
      options: ["a woman", "a man", "non-binary"]
    },
    {
      id: 'looking_for',
      question: "Looking for",
      type: 'single',
      options: ["a woman", "a man", "non-binary"]
    },
    {
      id: 'places',
      question: "Places I want to explore in SF:",
      type: 'multiple',
      options: ["nature & parks", "bars & speakeasies", "restaurants", "coffee & tea shops", "art & galleries"]
    },
    {
      id: 'priorities',
      question: "What are 3 most important things on this list?",
      type: 'multiple',
      maxSelections: 3,
      options: ["family", "career", "impact", "independence", "adventure"]
    },
    {
      id: 'social',
      question: "Your Instagram or LinkedIn:",
      type: 'text',
      placeholder: "your IG or LinkedIn handle"
    },
    {
      id: 'email',
      question: "Your email:",
      type: 'text',
      placeholder: "email@email.com"
    }
  ];

  const handleAnswer = async (answer) => {
    const newAnswers = { ...answers, [questions[currentQuestion].id]: answer };
    setAnswers(newAnswers);

    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
      if (questions[currentQuestion + 1].id === 'places') {
        setSelectedPlaces(answers.places || []);
      } else if (questions[currentQuestion + 1].id === 'priorities') {
        setSelectedPriorities(answers.priorities || []);
      }
      setCurrentInput('');
    } else {
      try {
        setShowThankYou(true);
        await addDoc(collection(db, 'survey_responses'), {
          ...newAnswers,
          timestamp: new Date(),
        });
        setTimeout(() => {
          onClose();
        }, 2000);
      } catch (error) {
        console.error('Error saving survey:', error);
        setTimeout(() => {
          onClose();
        }, 2000);
      }
    }
  };

  const handleBack = () => {
    if (currentQuestion > 0) {
      setCurrentQuestion(currentQuestion - 1);
      if (questions[currentQuestion - 1].id === 'places') {
        setSelectedPlaces(answers.places || []);
      } else if (questions[currentQuestion - 1].id === 'priorities') {
        setSelectedPriorities(answers.priorities || []);
      }
      setCurrentInput(answers[questions[currentQuestion - 1].id] || '');
    }
  };

  const handleTextInput = (event) => {
    setCurrentInput(event.target.value);
    if (event.key === 'Enter' && event.target.value.trim()) {
      handleAnswer(event.target.value.trim());
    }
  };

  const handleMultipleChoice = (option) => {
    const question = questions[currentQuestion];
    const currentSelected = question.id === 'places' ? selectedPlaces : selectedPriorities;
    const setSelected = question.id === 'places' ? setSelectedPlaces : setSelectedPriorities;
    
    if (currentSelected.includes(option)) {
      setSelected(currentSelected.filter(item => item !== option));
    } else if (!question.maxSelections || currentSelected.length < question.maxSelections) {
      setSelected([...currentSelected, option]);
    }
  };

  const handleNext = () => {
    const question = questions[currentQuestion];
    if (question.type === 'multiple') {
      if (question.id === 'places') {
        handleAnswer(selectedPlaces);
      } else if (question.id === 'priorities') {
        handleAnswer(selectedPriorities);
      }
    } else if (question.type === 'text' && currentInput.trim()) {
      handleAnswer(currentInput.trim());
    }
  };

  const renderQuestionContent = () => {
    const question = questions[currentQuestion];

    switch (question.type) {
      case 'text':
        return (
          <input
            type="text"
            className="survey-input"
            placeholder={question.placeholder}
            value={currentInput}
            onChange={handleTextInput}
            onKeyPress={(e) => {
              if (e.key === 'Enter' && currentInput.trim()) {
                handleAnswer(currentInput.trim());
              }
            }}
          />
        );

      case 'multiple':
        const currentSelected = question.id === 'places' ? selectedPlaces : selectedPriorities;
        return (
          <div className="survey-checkbox-group">
            {question.options.map((option, index) => (
              <button
                key={index}
                className={`survey-checkbox-option ${
                  currentSelected.includes(option) ? 'selected' : ''
                }`}
                onClick={() => handleMultipleChoice(option)}
                disabled={question.maxSelections && currentSelected.length >= question.maxSelections && !currentSelected.includes(option)}
              >
                {option}
              </button>
            ))}
          </div>
        );

      default:
        return (
          <div className="survey-options">
            {question.options.map((option, index) => (
              <button
                key={index}
                className="survey-option"
                onClick={() => handleAnswer(option)}
              >
                {option}
              </button>
            ))}
          </div>
        );
    }
  };

  const shouldShowNext = () => {
    const question = questions[currentQuestion];
    return question.type === 'multiple' || question.type === 'text';
  };

  const isNextDisabled = () => {
    const question = questions[currentQuestion];
    if (question.type === 'multiple') {
      const currentSelected = question.id === 'places' ? selectedPlaces : selectedPriorities;
      if (question.maxSelections) {
        return currentSelected.length !== question.maxSelections;
      }
      return currentSelected.length === 0;
    } else if (question.type === 'text') {
      return !currentInput.trim();
    }
    return false;
  };

  return (
    <div className={`survey-overlay ${className || ''}`}>
      <div className="survey-container">
        <button className="survey-close" onClick={onClose}>×</button>
        {!showThankYou && (
          <>
            <button 
              className={`survey-back ${currentQuestion > 0 ? 'visible' : ''}`}
              onClick={handleBack}
              aria-label="Go back to previous question"
            >
              ‹
            </button>
            {shouldShowNext() && (
              <button
                className="survey-next"
                onClick={handleNext}
                disabled={isNextDisabled()}
                aria-label="Next question"
              >
                ›
              </button>
            )}
          </>
        )}
        
        {!showThankYou && currentQuestion === 0 && (
          <div className="survey-header">
            <h1>
              <span className="highlight">Match</span> based on the <span className="highlight">places you want</span> to explore. Chat & plan your <span className="highlight">unique LUV mission</span>. Meet IRL to complete your mission and <span className="highlight">have fun</span>!
            </h1>
            <h1>
              Sign up for our launch in <span className="highlight">SF</span> and <span className="highlight">win prizes</span> to redeem at your date!
            </h1>
          </div>
        )}

        <div className="survey-progress">
          <div 
            className="survey-progress-bar"
            style={{ width: showThankYou ? '100%' : `${((currentQuestion + 1) / questions.length) * 100}%` }}
          />
        </div>

        {!showThankYou ? (
          <>            
            <h2 className="survey-question">
              {questions[currentQuestion].question}
            </h2>

            {renderQuestionContent()}
          </>
        ) : (
          <div className="survey-thank-you">
            <h2 className="survey-question">Thank you for your responses. We're launching in SF soon. Stay tuned for an email from LUV!</h2>
          </div>
        )}
      </div>
    </div>
  );
};

export default Survey; 