import React, { useState, useEffect, useRef, useMemo } from 'react';
import { db } from '../firebase';
import { collection, addDoc } from 'firebase/firestore';
import './Survey.css';
import sfDateSpots from '../data/sfDateSpots';

// Separate the LearnMorePopup as a standalone component that can be used independently
export const LearnMorePopup = ({ onClose, onTry }) => {
  const [currentCardIndex, setCurrentCardIndex] = useState(0);
  
  // Features content
  const features = [
    {
      title: "Design a LUV mission",
      description: "Use LUV tools to create a unique real-life date experience, get your own romantic spot, and win rewards!"
    },
    {
      title: "Share a LUV mission",
      description: "Invite someone you know or find a LUV match on our platform. Match based on the places you want to explore in person!"
    },
    {
      title: "Make every date special",
      description: "Every LUV mission is unique. Complete LUV missions in real life, have fun, and win rewards!"
    }
  ];
  
  const nextCard = () => {
    setCurrentCardIndex((prevIndex) => (prevIndex + 1) % features.length);
  };
  
  const prevCard = () => {
    setCurrentCardIndex((prevIndex) => (prevIndex - 1 + features.length) % features.length);
  };

  return (
    <div className="learn-more-overlay">
      <div className="learn-more-container">
        <button className="learn-more-close" onClick={onClose}>×</button>
        
        <h2 style={{ 
          fontWeight: 'bold', 
          marginBottom: '16px', 
          color: 'rgba(147, 51, 234, 0.8)', 
          textShadow: '0 0 5px rgba(147, 51, 234, 0.6), 0 0 15px rgba(147, 51, 234, 0.5), 0 0 20px rgba(147, 51, 234, 0.4)', 
          letterSpacing: '1px'
        }}>LUV MISSION: TURN YOUR DATE INTO A QUEST</h2>
        
        <p style={{ fontStyle: 'italic', marginBottom: '24px' }}>
          No date? We'll help you find a match based on the places you both want to explore!
        </p>
        
        <div className="learn-more-carousel">
          <button 
            className="carousel-nav prev" 
            onClick={prevCard}
            aria-label="Previous feature"
          >
            ‹
          </button>
          
          <div className="learn-more-feature active" style={{ height: 'clamp(160px, 25vh, 200px)', display: 'flex', flexDirection: 'column', justifyContent: 'center', overflow: 'hidden' }}>
            <h3>{features[currentCardIndex].title}</h3>
            <p>{features[currentCardIndex].description}</p>
          </div>
          
          <button 
            className="carousel-nav next" 
            onClick={nextCard}
            aria-label="Next feature"
          >
            ›
          </button>
        </div>
        
        <div className="carousel-indicators">
          {features.map((_, index) => (
            <span 
              key={index} 
              className={`carousel-dot ${index === currentCardIndex ? 'active' : ''}`}
              onClick={() => setCurrentCardIndex(index)}
            />
          ))}
        </div>
        
        <button className="learn-more-try" onClick={onTry}>TRY</button>
      </div>
    </div>
  );
};

// Define San Francisco zip codes to neighborhoods mapping
const SF_ZIP_TO_NEIGHBORHOOD = {
  '94102': 'Tenderloin/Civic Center',
  '94103': 'SoMa',
  '94104': 'Financial District',
  '94105': 'Embarcadero/SoMa',
  '94107': 'Potrero Hill/Dogpatch',
  '94108': 'Chinatown/Financial District',
  '94109': 'Nob Hill/Russian Hill',
  '94110': 'Mission District',
  '94111': 'Financial District/Embarcadero',
  '94112': 'Ingleside/Excelsior',
  '94114': 'Castro/Noe Valley',
  '94115': 'Pacific Heights/Japantown',
  '94116': 'Sunset',
  '94117': 'Haight-Ashbury',
  '94118': 'Richmond/Presidio Heights',
  '94121': 'Richmond/Seacliff',
  '94122': 'Sunset',
  '94123': 'Marina/Cow Hollow',
  '94124': 'Bayview/Hunters Point',
  '94127': 'West Portal/St. Francis Wood',
  '94129': 'Presidio',
  '94130': 'Treasure Island',
  '94131': 'Twin Peaks/Glen Park',
  '94132': 'Lake Merced/SF State',
  '94133': 'North Beach/Telegraph Hill',
  '94134': 'Visitacion Valley',
  '94158': 'Mission Bay'
};

// List of known San Francisco neighborhoods to check for
const SF_NEIGHBORHOODS = [
  "Mission District", "Mission", "SoMa", "South of Market", "Marina", "Castro", 
  "Noe Valley", "North Beach", "Haight-Ashbury", "Haight", "Richmond", "Sunset", 
  "Hayes Valley", "Tenderloin", "Financial District", "Chinatown", "Japantown", 
  "Pacific Heights", "Nob Hill", "Russian Hill", "Potrero Hill", "Dogpatch", 
  "Embarcadero", "Fillmore", "Western Addition", "Bernal Heights", "Cole Valley",
  "Twin Peaks", "Bayview", "Hunters Point", "Excelsior", "Glen Park", "Ingleside",
  "Presidio", "Inner Richmond", "Outer Richmond", "Inner Sunset", "Outer Sunset",
  "Cow Hollow", "Lower Haight", "Mission Bay", "Visitacion Valley", "Treasure Island",
  "Telegraph Hill", "Civic Center", "Lake Merced", "West Portal", "St. Francis Wood"
];

const LuvMissionSurvey = ({ onClose, onFindMatch, showLearnMore, onLearnMoreClose }) => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answers, setAnswers] = useState({});
  const [showSummary, setShowSummary] = useState(false);
  const [currentInput, setCurrentInput] = useState('');
  const [marker, setMarker] = useState(null);
  const [map, setMap] = useState(null);
  const [autocomplete, setAutocomplete] = useState(null);
  const [infoWindow, setInfoWindow] = useState(null);
  const [isLoadingTrending, setIsLoadingTrending] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [missionChallenges, setMissionChallenges] = useState({});
  const [isLoadingProtocol, setIsLoadingProtocol] = useState(false);
  const mapRef = useRef(null);
  const autocompleteInputRef = useRef(null);
  const prevQuestionRef = useRef(-1); // Track previous question for flow direction
  const [shareLoading, setShareLoading] = useState(false);
  const [shareURL, setShareURL] = useState(null);
  const [showScrollIndicator, setShowScrollIndicator] = useState(true);
  const missionDetailsRef = useRef(null);

  // Mission protocol and challenge pairs
  const protocolChallengePairs = [
    {
      protocol: "choose and wear a purple accessory",
      challenge: "When you meet, reveal your purple item by snapping a fun selfie at a local landmark."
    },
    {
      protocol: "create and agree on your secret spy code names",
      challenge: "Introduce yourselves using your code names throughout the date."
    },
    {
      protocol: "practice a playful spy accent",
      challenge: "Use your accent for your opening greeting and during one key conversation."
    },
    {
      protocol: "write a mysterious note with a hidden message",
      challenge: "Exchange and read your pre-written note as an icebreaker."
    },
    {
      protocol: "devise a secret handshake or signal",
      challenge: "Perform your pre-arranged handshake as a friendly welcome."
    },
    {
      protocol: "pick a quirky \"spy gadget\" (like a fun hat or sunglasses) to sport",
      challenge: "Show off your gadget when you first meet."
    },
    {
      protocol: "curate a mini playlist with spy-vibe tunes",
      challenge: "Share a snippet of your playlist during a coffee break."
    },
    {
      protocol: "research a fun fact about San Francisco",
      challenge: "Reveal your quirky SF fact at a scenic spot during your date."
    },
    {
      protocol: "prepare a coded compliment",
      challenge: "Deliver your pre-planned compliment during your initial conversation."
    },
    {
      protocol: "choose a small token or memento with personal meaning",
      challenge: "Exchange your token and share its backstory over a casual drink."
    }
  ];

  const questions = useMemo(() => [
    {
      id: 'mission_type',
      question: "Design a LUV mission:",
      type: 'single',
      options: ["you + someone you know", "you + a LUV match", "your friend + your friend"]
    },
    {
      id: 'location',
      question: "Choose a spot for your LUV mission:",
      type: 'map',
      placeholder: "Search for a location in San Francisco"
    },
    {
      id: 'qualifier',
      question: "Create a mission qualifier:",
      type: 'text',
      placeholder: "e.g., sport at least one purple accessory or clothing"
    }
  ], []);

  // Initialize or recreate map when navigating to question 1
  useEffect(() => {
    // Check if we're navigating to question 1 from any direction
    if (currentQuestion === 1 && window.google) {
      // Record that we've been to question 1 for the useEffect cleanup
      prevQuestionRef.current = currentQuestion;
      
      // Clean up existing map if there is one
      if (map) {
        // Remove the old map instance to prevent memory leaks
        setMap(null);
      }

      // Store the current qualifier input if we're coming from question 2
      if (prevQuestionRef.current === 2 && currentInput.trim()) {
        const qualifierId = questions[2].id;
        setAnswers(prev => ({
          ...prev,
          [qualifierId]: currentInput.trim()
        }));
      }

      // If we have a selectedLocation, update the input field
      if (selectedLocation && selectedLocation.place) {
        const shortAddress = selectedLocation.place.formatted_address.split(',')[0];
        setCurrentInput(`${selectedLocation.place.name} - ${shortAddress}`);
      }

      try {
        console.log('Initializing map...');
        const sanFrancisco = { lat: 37.7749, lng: -122.4194 };
        const newMap = new window.google.maps.Map(mapRef.current, {
          center: selectedLocation ? selectedLocation.location : sanFrancisco,
          zoom: selectedLocation ? 15 : 13,
          mapId: 'bc41591a20f6dde4'
        });
        console.log('Map initialized:', newMap);
        setMap(newMap);

        // Initialize info window
        const newInfoWindow = new window.google.maps.InfoWindow({
          maxWidth: 300,
          pixelOffset: new window.google.maps.Size(0, -20)
        });
        setInfoWindow(newInfoWindow);

        // Restore marker if we have a selected location
        if (selectedLocation) {
          const markerView = document.createElement('div');
          markerView.style.width = '30px';
          markerView.style.height = '30px';
          markerView.style.backgroundColor = '#ff69b4';
          markerView.style.borderRadius = '50%';
          markerView.style.border = '3px solid white';
          markerView.style.boxShadow = '0 0 15px rgba(147, 51, 234, 0.5), 0 2px 4px rgba(0,0,0,0.2)';
          markerView.style.zIndex = '1000';
          markerView.style.position = 'relative';
          markerView.style.cursor = 'pointer';

          window.google.maps.importLibrary("marker").then(({ AdvancedMarkerElement }) => {
            const newMarker = new AdvancedMarkerElement({
              map: newMap,
              position: selectedLocation.location,
              content: markerView,
              zIndex: 1000
            });

            // Add click listener for the info window
            markerView.addEventListener('click', () => {
              if (newInfoWindow.getMap()) {
                newInfoWindow.close();
              } else {
                newInfoWindow.setContent(selectedLocation.infoContent);
                newInfoWindow.open(newMap, newMarker);
              }
            });

            // Show info window automatically
            newInfoWindow.setContent(selectedLocation.infoContent);
            newInfoWindow.open(newMap, newMarker);

            setMarker(newMarker);
          });
        }
      } catch (error) {
        console.error('Error initializing Google Maps:', error);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentQuestion, selectedLocation, questions]);

  // Track question changes to handle map cleanup when leaving question 1
  useEffect(() => {
    // When leaving question 1, clean up map-related resources
    if (prevQuestionRef.current === 1 && currentQuestion !== 1) {
      if (map) {
        // Just set visibility to hidden - we'll recreate it when needed
        if (mapRef.current) {
          mapRef.current.style.visibility = 'hidden';
        }
      }
    }
    
    // Update the previous question ref
    prevQuestionRef.current = currentQuestion;
  }, [currentQuestion, map]);

  // Save current input whenever currentQuestion changes
  useEffect(() => {
    // Save current input to answers when navigating away
    if (currentInput.trim() && currentQuestion >= 0 && currentQuestion < questions.length) {
      const questionId = questions[currentQuestion].id;
      setAnswers(prev => ({
        ...prev,
        [questionId]: currentInput.trim()
      }));
    }
  }, [currentQuestion, questions, currentInput]);
  
  // Restore answers when navigating to a question
  useEffect(() => {
    if (currentQuestion === 2) {
      const qualifierId = questions[2].id;
      if (answers[qualifierId]) {
        setCurrentInput(answers[qualifierId]);
      }
    }
  }, [currentQuestion, answers, questions]);

  // Initialize autocomplete when map is available
  useEffect(() => {
    if (map && window.google && currentQuestion === 1) {
      try {
        console.log('Initializing autocomplete...');
        
        // Clear any existing autocomplete to prevent duplicates
        if (autocomplete) {
          window.google.maps.event.clearInstanceListeners(autocompleteInputRef.current);
        }
        
        // Remove any existing Google autocomplete containers from the DOM
        const existingAutocompleteContainers = document.querySelectorAll('.pac-container');
        existingAutocompleteContainers.forEach(container => {
          container.remove();
        });
        
        const newAutocomplete = new window.google.maps.places.Autocomplete(autocompleteInputRef.current, {
          bounds: new window.google.maps.LatLngBounds(
            new window.google.maps.LatLng(37.6989, -122.5146),  // Southwest corner (includes Outer Sunset and Lake Merced)
            new window.google.maps.LatLng(37.8324, -122.3555)   // Northeast corner (includes Treasure Island and North Beach)
          ),
          strictBounds: true,
          types: ['establishment']  // Most comprehensive type that includes businesses, venues, and points of interest
        });

        // Flag to track if autocomplete selection is in progress - store on window for global access
        window.isSelectingAutocomplete = false;

        // Add event listeners to detect keyboard navigation in autocomplete dropdown
        autocompleteInputRef.current.addEventListener('keydown', (e) => {
          // Down arrow key (40), Up arrow key (38), or Tab key (9) indicates autocomplete navigation
          if (e.keyCode === 40 || e.keyCode === 38 || e.keyCode === 9) {
            window.isSelectingAutocomplete = true;
          }
          
          // Enter key (13) - only reset if we're NOT in selection mode to avoid race conditions
          if (e.keyCode === 13 && !window.isSelectingAutocomplete) {
            window.isSelectingAutocomplete = false;
          }
        });

        newAutocomplete.addListener('place_changed', async () => {
          const place = newAutocomplete.getPlace();
          console.log('Place selected:', place);
          console.log('Place geometry:', place.geometry);
          console.log('Place location:', place.geometry?.location);
          
          // Reset autocomplete selection flag after a small delay to ensure
          // it doesn't get reset before related keypress events are processed
          setTimeout(() => {
            window.isSelectingAutocomplete = false;
          }, 100);

          if (place.geometry && place.geometry.location) {
            console.log('Updating map center and zoom...');
            // Update map
            map.setCenter(place.geometry.location);
            map.setZoom(15);

            // Update marker
            if (marker) {
              console.log('Removing existing marker...');
              marker.map = null;
            }

            try {
              console.log('Creating new marker...');
              const markerView = document.createElement('div');
              markerView.style.width = '30px';
              markerView.style.height = '30px';
              markerView.style.backgroundColor = '#ff69b4';
              markerView.style.borderRadius = '50%';
              markerView.style.border = '3px solid white';
              markerView.style.boxShadow = '0 0 15px rgba(147, 51, 234, 0.5), 0 2px 4px rgba(0,0,0,0.2)';
              markerView.style.zIndex = '1000';
              markerView.style.position = 'relative';
              markerView.style.cursor = 'pointer';
              
              // Import the marker library
              const { AdvancedMarkerElement } = await window.google.maps.importLibrary("marker");
              console.log('Marker library imported');
              
              const newMarker = new AdvancedMarkerElement({
                map: map,
                position: place.geometry.location,
                content: markerView,
                zIndex: 1000
              });

              // Check if this place exists in our database
              const matchingSpot = findMatchingSpot(place);
              
              // Add click listener to the marker for toggling info window
              markerView.addEventListener('click', () => {
                if (infoWindow.getMap()) {
                  infoWindow.close();
                } else {
                  // If we have a matching spot in our database, use that data
                  if (matchingSpot) {
                    // Format address to remove ZIP code and "United States"
                    const formatAddress = (address) => {
                      // Split the address into parts
                      const parts = address.split(',');
                      
                      // Remove "United States" (usually the last part)
                      if (parts.length > 0 && parts[parts.length - 1].trim() === "United States") {
                        parts.pop();
                      }
                      
                      // Remove ZIP code (usually found in the second-to-last part with state)
                      if (parts.length > 1) {
                        // The state part usually looks like " CA 94110" or " California 94110"
                        const stateZipPart = parts[parts.length - 1].trim();
                        const statePart = stateZipPart.split(' ')[0]; // Keep just the state abbreviation
                        parts[parts.length - 1] = ` ${statePart}`; // Replace with just the state
                      }
                      
                      // Join parts back
                      return parts.join(',');
                    };

                    const content = `
                      <div style="padding: 8px;">
                        ${place.photos && place.photos[0] ? `
                          <div style="margin-bottom: 8px; border-radius: 4px; overflow: hidden; display: flex; justify-content: center;">
                            <img 
                              src="${place.photos[0].getUrl({ 
                                maxWidth: 400,
                                maxHeight: 200,
                                crop: 'center',
                                quality: 'high'
                              })}" 
                              alt="${matchingSpot.name}"
                              style="width: 50%; height: 60px; object-fit: cover;"
                            />
                          </div>
                        ` : ''}
                        <h3 style="margin: 0 0 4px 0; color: #333;">${matchingSpot.name}</h3>
                        <p style="margin: 0 0 4px 0; color: #9333ea; font-size: 12px;">
                          ${matchingSpot.locationDescription}
                        </p>
                        <p style="margin: 0; color: #666; font-size: 14px;">${formatAddress(matchingSpot.address)}</p>
                        ${matchingSpot.highlights && matchingSpot.highlights.length > 0 ? `
                          <p style="margin: 4px 0 0 0; color: #444; font-size: 12px; font-style: italic;">
                            "${matchingSpot.highlights[0]}"
                          </p>
                        ` : ''}
                      </div>
                    `;
                    infoWindow.setContent(content);
                    infoWindow.open(map, newMarker);
                  } else {
                    // Get description from editorial_summary or generate one from types
                    let description = '';
                    if (place.editorial_summary && place.editorial_summary.overview) {
                      description = place.editorial_summary.overview;
                    } else if (place.types && place.types.length > 0) {
                      // Convert first two types to readable format
                      const readableTypes = place.types
                        .slice(0, 1)
                        .map(type => type.replace(/_/g, ' '))
                        .join('');
                      
                      // Better neighborhood extraction with SF neighborhoods and zip codes
                      let neighborhood = 'San Francisco';
                      const addressParts = place.formatted_address.split(',');
                      
                      // Map of SF zip codes to neighborhoods - use the global constant
                      const sfZipToNeighborhood = SF_ZIP_TO_NEIGHBORHOOD;
                      
                      // List of known San Francisco neighborhoods to check for
                      const sfNeighborhoods = SF_NEIGHBORHOODS;
                      
                      // First try to extract zip code and match to neighborhood
                      const zipCodeMatch = place.formatted_address.match(/\b(94\d{3})\b/);
                      if (zipCodeMatch && zipCodeMatch[1] && sfZipToNeighborhood[zipCodeMatch[1]]) {
                        neighborhood = sfZipToNeighborhood[zipCodeMatch[1]];
                      } else {
                        // If no zip code, fall back to looking for neighborhood names
                        const foundNeighborhood = addressParts.find(part => 
                          sfNeighborhoods.some(n => part.trim().includes(n))
                        );
                        
                        if (foundNeighborhood) {
                          // If we found a neighborhood, extract just that part
                          neighborhood = foundNeighborhood.trim();
                        } else {
                          // If no known neighborhood, try to find something that looks like one
                          // Check each part that isn't the street address or city/state/zip
                          for (let i = 1; i < addressParts.length - 1; i++) {
                            const part = addressParts[i].trim();
                            // Skip parts that are likely not neighborhoods
                            if (!part.includes("San Francisco") && 
                                !part.includes("CA") && 
                                !/^\d{5}$/.test(part) &&
                                part.length > 1) {
                              neighborhood = part;
                              break;
                            }
                          }
                        }
                      }
                      
                      description = `${readableTypes} in ${neighborhood}`;
                    }

                    // Format address to remove ZIP code and "United States"
                    const formatAddress = (address) => {
                      // Split the address into parts
                      const parts = address.split(',');
                      
                      // Remove "United States" (usually the last part)
                      if (parts.length > 0 && parts[parts.length - 1].trim() === "United States") {
                        parts.pop();
                      }
                      
                      // Remove ZIP code (usually found in the second-to-last part with state)
                      if (parts.length > 1) {
                        // The state part usually looks like " CA 94110" or " California 94110"
                        const stateZipPart = parts[parts.length - 1].trim();
                        const statePart = stateZipPart.split(' ')[0]; // Keep just the state abbreviation
                        parts[parts.length - 1] = ` ${statePart}`; // Replace with just the state
                      }
                      
                      // Join parts back
                      return parts.join(',');
                    };

                    const content = `
                      <div style="padding: 8px;">
                        ${place.photos && place.photos[0] ? `
                          <div style="margin-bottom: 8px; border-radius: 4px; overflow: hidden; display: flex; justify-content: center;">
                            <img 
                              src="${place.photos[0].getUrl({ 
                                maxWidth: 400,
                                maxHeight: 200,
                                crop: 'center',
                                quality: 'high'
                              })}" 
                              alt="${place.name || 'Location photo'}"
                              style="width: 50%; height: 60px; object-fit: cover;"
                            />
                          </div>
                        ` : ''}
                        <h3 style="margin: 0 0 4px 0; color: #333;">${place.name || 'Selected Location'}</h3>
                        <p style="margin: 0 0 4px 0; color: #9333ea; font-size: 12px;">
                          ${description}
                        </p>
                        <p style="margin: 0; color: #666; font-size: 14px;">${formatAddress(place.formatted_address)}</p>
                      </div>
                    `;
                    infoWindow.setContent(content);
                    infoWindow.open(map, newMarker);
                  }
                }
              });

              // Show info window automatically
              // If we have a matching spot in our database, use that data
              if (matchingSpot) {
                // Format address to remove ZIP code and "United States"
                const formatAddress = (address) => {
                  // Split the address into parts
                  const parts = address.split(',');
                  
                  // Remove "United States" (usually the last part)
                  if (parts.length > 0 && parts[parts.length - 1].trim() === "United States") {
                    parts.pop();
                  }
                  
                  // Remove ZIP code (usually found in the second-to-last part with state)
                  if (parts.length > 1) {
                    // The state part usually looks like " CA 94110" or " California 94110"
                    const stateZipPart = parts[parts.length - 1].trim();
                    const statePart = stateZipPart.split(' ')[0]; // Keep just the state abbreviation
                    parts[parts.length - 1] = ` ${statePart}`; // Replace with just the state
                  }
                  
                  // Join parts back
                  return parts.join(',');
                };

                const content = `
                  <div style="padding: 8px;">
                    ${place.photos && place.photos[0] ? `
                      <div style="margin-bottom: 8px; border-radius: 4px; overflow: hidden; display: flex; justify-content: center;">
                        <img 
                          src="${place.photos[0].getUrl({ 
                            maxWidth: 400,
                            maxHeight: 200,
                            crop: 'center',
                            quality: 'high'
                          })}" 
                          alt="${matchingSpot.name}"
                          style="width: 50%; height: 60px; object-fit: cover;"
                        />
                      </div>
                    ` : ''}
                    <h3 style="margin: 0 0 4px 0; color: #333;">${matchingSpot.name}</h3>
                    <p style="margin: 0 0 4px 0; color: #9333ea; font-size: 12px;">
                      ${matchingSpot.locationDescription}
                    </p>
                    <p style="margin: 0; color: #666; font-size: 14px;">${formatAddress(matchingSpot.address)}</p>
                    ${matchingSpot.highlights && matchingSpot.highlights.length > 0 ? `
                      <p style="margin: 4px 0 0 0; color: #444; font-size: 12px; font-style: italic;">
                        "${matchingSpot.highlights[0]}"
                      </p>
                    ` : ''}
                  </div>
                `;
                infoWindow.setContent(content);
                infoWindow.open(map, newMarker);

                // After creating the infoWindow content and showing it
                // Store the selected location for later restoration with our data
                setSelectedLocation({
                  location: place.geometry.location,
                  infoContent: content,
                  place: {
                    ...place,
                    name: matchingSpot.name,
                    formatted_address: matchingSpot.address,
                    editorial_summary: { overview: matchingSpot.locationDescription }
                  }
                });
                
                // Pre-populate answers with database information for the summary
                setAnswers(current => ({
                  ...current,
                  locationDetails: {
                    name: matchingSpot.name,
                    formattedAddress: formatAddress(matchingSpot.address),
                    description: matchingSpot.locationDescription,
                    photoUrl: place.photos && place.photos[0] ? 
                      place.photos[0].getUrl({ 
                        maxWidth: 800,
                        maxHeight: 500,
                        crop: 'center',
                        quality: 'highest'
                      }) : null,
                    highlights: matchingSpot.highlights || null,
                    specialNotes: matchingSpot.specialNotes || null
                  }
                }));
                
                // Always try to get the most current hours from Google Places API first
                if (place.place_id) {
                  const detailsService = new window.google.maps.places.PlacesService(map);
                  detailsService.getDetails({
                    placeId: place.place_id,
                    fields: ['opening_hours']
                  }, (detailedPlace, status) => {
                    if (status === window.google.maps.places.PlacesServiceStatus.OK && 
                        detailedPlace && detailedPlace.opening_hours && detailedPlace.opening_hours.weekday_text) {
                      // Update with fresh hours from Google
                      const formattedHours = detailedPlace.opening_hours.weekday_text.join(', ');
                      setAnswers(current => {
                        if (current.locationDetails) {
                          return {
                            ...current,
                            locationDetails: {
                              ...current.locationDetails,
                              hours: formattedHours
                            }
                          };
                        }
                        return current;
                      });
                    } else if (matchingSpot.hours) {
                      // Fall back to our database hours if Google API fails
                      setAnswers(current => {
                        if (current.locationDetails) {
                          return {
                            ...current,
                            locationDetails: {
                              ...current.locationDetails,
                              hours: matchingSpot.hours
                            }
                          };
                        }
                        return current;
                      });
                    }
                  });
                } else if (matchingSpot.hours) {
                  // If no place_id, use our database hours
                  setAnswers(current => ({
                    ...current,
                    locationDetails: {
                      ...current.locationDetails,
                      hours: matchingSpot.hours
                    }
                  }));
                }
              } else {
                // Get description from editorial_summary or generate one from types
                let description = '';
                if (place.editorial_summary && place.editorial_summary.overview) {
                  description = place.editorial_summary.overview;
                } else if (place.types && place.types.length > 0) {
                  // Convert first two types to readable format
                  const readableTypes = place.types
                    .slice(0, 1)
                    .map(type => type.replace(/_/g, ' '))
                    .join('');
                  
                  // Better neighborhood extraction with SF neighborhoods and zip codes
                  let neighborhood = 'San Francisco';
                  const addressParts = place.formatted_address.split(',');
                  
                  // Map of SF zip codes to neighborhoods - use the global constant
                  const sfZipToNeighborhood = SF_ZIP_TO_NEIGHBORHOOD;
                  
                  // List of known San Francisco neighborhoods to check for
                  const sfNeighborhoods = SF_NEIGHBORHOODS;
                  
                  // First try to extract zip code and match to neighborhood
                  const zipCodeMatch = place.formatted_address.match(/\b(94\d{3})\b/);
                  if (zipCodeMatch && zipCodeMatch[1] && sfZipToNeighborhood[zipCodeMatch[1]]) {
                    neighborhood = sfZipToNeighborhood[zipCodeMatch[1]];
                  } else {
                    // If no zip code, fall back to looking for neighborhood names
                    const foundNeighborhood = addressParts.find(part => 
                      sfNeighborhoods.some(n => part.trim().includes(n))
                    );
                    
                    if (foundNeighborhood) {
                      // If we found a neighborhood, extract just that part
                      neighborhood = foundNeighborhood.trim();
                    } else {
                      // If no known neighborhood, try to find something that looks like one
                      // Check each part that isn't the street address or city/state/zip
                      for (let i = 1; i < addressParts.length - 1; i++) {
                        const part = addressParts[i].trim();
                        // Skip parts that are likely not neighborhoods
                        if (!part.includes("San Francisco") && 
                            !part.includes("CA") && 
                            !/^\d{5}$/.test(part) &&
                            part.length > 1) {
                          neighborhood = part;
                          break;
                        }
                      }
                    }
                  }
                  
                  description = `${readableTypes} in ${neighborhood}`;
                }
                
                // Format address to remove ZIP code and "United States"
                const formatAddress = (address) => {
                  // Split the address into parts
                  const parts = address.split(',');
                  
                  // Remove "United States" (usually the last part)
                  if (parts.length > 0 && parts[parts.length - 1].trim() === "United States") {
                    parts.pop();
                  }
                  
                  // Remove ZIP code (usually found in the second-to-last part with state)
                  if (parts.length > 1) {
                    // The state part usually looks like " CA 94110" or " California 94110"
                    const stateZipPart = parts[parts.length - 1].trim();
                    const statePart = stateZipPart.split(' ')[0]; // Keep just the state abbreviation
                    parts[parts.length - 1] = ` ${statePart}`; // Replace with just the state
                  }
                  
                  // Join parts back
                  return parts.join(',');
                };

                const content = `
                  <div style="padding: 8px;">
                    ${place.photos && place.photos[0] ? `
                      <div style="margin-bottom: 8px; border-radius: 4px; overflow: hidden; display: flex; justify-content: center;">
                        <img 
                          src="${place.photos[0].getUrl({ 
                            maxWidth: 400,
                            maxHeight: 200,
                            crop: 'center',
                            quality: 'high'
                          })}" 
                          alt="${place.name || 'Location photo'}"
                          style="width: 50%; height: 60px; object-fit: cover;"
                        />
                      </div>
                    ` : ''}
                    <h3 style="margin: 0 0 4px 0; color: #333;">${place.name || 'Selected Location'}</h3>
                    <p style="margin: 0 0 4px 0; color: #9333ea; font-size: 12px;">
                      ${description}
                    </p>
                    <p style="margin: 0; color: #666; font-size: 14px;">${formatAddress(place.formatted_address)}</p>
                  </div>
                `;
                infoWindow.setContent(content);
                infoWindow.open(map, newMarker);

                // After creating the infoWindow content and showing it
                // Store the selected location for later restoration
                setSelectedLocation({
                  location: place.geometry.location,
                  infoContent: content,
                  place: place
                });
              }

              setMarker(newMarker);

              // Update input value with name and short address
              const shortAddress = place.formatted_address.split(',')[0];
              const matchedName = matchingSpot ? matchingSpot.name : place.name;
              setCurrentInput(`${matchedName} - ${shortAddress}`);
            } catch (error) {
              console.error('Error creating marker:', error);
            }
          } else {
            console.log('Place has no geometry or location:', place);
            // Try to get the location using Geocoding service
            const geocoder = new window.google.maps.Geocoder();
            geocoder.geocode({ address: place.formatted_address }, (results, status) => {
              if (status === 'OK' && results[0]) {
                console.log('Geocoding result:', results[0]);
                const location = results[0].geometry.location;
                console.log('Updating map with geocoded location...');
                map.setCenter(location);
                map.setZoom(15);

                // Create marker at geocoded location
                if (marker) {
                  console.log('Removing existing marker...');
                  marker.map = null;
                }

                const markerView = document.createElement('div');
                markerView.style.width = '30px';
                markerView.style.height = '30px';
                markerView.style.backgroundColor = '#ff69b4';
                markerView.style.borderRadius = '50%';
                markerView.style.border = '3px solid white';
                markerView.style.boxShadow = '0 0 15px rgba(147, 51, 234, 0.5), 0 2px 4px rgba(0,0,0,0.2)';
                markerView.style.zIndex = '1000';
                markerView.style.position = 'relative';
                markerView.style.cursor = 'pointer';

                window.google.maps.importLibrary("marker").then(({ AdvancedMarkerElement }) => {
                  console.log('Creating marker from geocoding...');
                  const newMarker = new AdvancedMarkerElement({
                    map: map,
                    position: location,
                    content: markerView,
                    zIndex: 1000
                  });

                  // Add click listener to the marker for toggling info window
                  markerView.addEventListener('click', () => {
                    if (infoWindow.getMap()) {
                      infoWindow.close();
                    } else {
                      // Generate description from types or address
                      let description = '';
                      if (place.types && place.types.length > 0) {
                        // Convert first two types to readable format
                        const readableTypes = place.types
                          .slice(0, 1)
                          .map(type => type.replace(/_/g, ' '))
                          .join('');
                        
                        // Better neighborhood extraction with SF neighborhoods and zip codes
                        let neighborhood = 'San Francisco';
                        const addressParts = place.formatted_address.split(',');
                        
                        // Map of SF zip codes to neighborhoods - use the global constant
                        const sfZipToNeighborhood = SF_ZIP_TO_NEIGHBORHOOD;
                        
                        // List of known San Francisco neighborhoods to check for
                        const sfNeighborhoods = SF_NEIGHBORHOODS;
                        
                        // First try to extract zip code and match to neighborhood
                        const zipCodeMatch = place.formatted_address.match(/\b(94\d{3})\b/);
                        if (zipCodeMatch && zipCodeMatch[1] && sfZipToNeighborhood[zipCodeMatch[1]]) {
                          neighborhood = sfZipToNeighborhood[zipCodeMatch[1]];
                        } else {
                          // If no zip code, fall back to looking for neighborhood names
                          const foundNeighborhood = addressParts.find(part => 
                            sfNeighborhoods.some(n => part.trim().includes(n))
                          );
                          
                          if (foundNeighborhood) {
                            // If we found a neighborhood, extract just that part
                            neighborhood = foundNeighborhood.trim();
                          } else {
                            // If no known neighborhood, try to find something that looks like one
                            // Check each part that isn't the street address or city/state/zip
                            for (let i = 1; i < addressParts.length - 1; i++) {
                              const part = addressParts[i].trim();
                              // Skip parts that are likely not neighborhoods
                              if (!part.includes("San Francisco") && 
                                  !part.includes("CA") && 
                                  !/^\d{5}$/.test(part) &&
                                  part.length > 1) {
                                neighborhood = part;
                                break;
                              }
                            }
                          }
                        }
                        
                        description = `${readableTypes} in ${neighborhood}`;
                      } else {
                        description = `Located in San Francisco`;
                      }
                      
                      // Format address to remove ZIP code and "United States"
                      const formatAddress = (address) => {
                        // Split the address into parts
                        const parts = address.split(',');
                        
                        // Remove "United States" (usually the last part)
                        if (parts.length > 0 && parts[parts.length - 1].trim() === "United States") {
                          parts.pop();
                        }
                        
                        // Remove ZIP code (usually found in the second-to-last part with state)
                        if (parts.length > 1) {
                          // The state part usually looks like " CA 94110" or " California 94110"
                          const stateZipPart = parts[parts.length - 1].trim();
                          const statePart = stateZipPart.split(' ')[0]; // Keep just the state abbreviation
                          parts[parts.length - 1] = ` ${statePart}`; // Replace with just the state
                        }
                        
                        // Join parts back
                        return parts.join(',');
                      };

                      const content = `
                        <div style="padding: 8px;">
                          <h3 style="margin: 0 0 4px 0; color: #333;">Selected Location</h3>
                          <p style="margin: 0 0 4px 0; color: #9333ea; font-size: 12px;">
                            ${description}
                          </p>
                          <p style="margin: 0; color: #666; font-size: 14px;">${formatAddress(place.formatted_address)}</p>
                        </div>
                      `;
                      infoWindow.setContent(content);
                      infoWindow.open(map, newMarker);
                    }
                  });

                  // Show info window automatically
                  // Generate description from types or address
                  let description = '';
                  if (place.types && place.types.length > 0) {
                    // Convert first two types to readable format
                    const readableTypes = place.types
                      .slice(0, 1)
                      .map(type => type.replace(/_/g, ' '))
                      .join('');
                    
                    // Better neighborhood extraction with SF neighborhoods and zip codes
                    let neighborhood = 'San Francisco';
                    const addressParts = place.formatted_address.split(',');
                    
                    // Map of SF zip codes to neighborhoods - use the global constant
                    const sfZipToNeighborhood = SF_ZIP_TO_NEIGHBORHOOD;
                    
                    // List of known San Francisco neighborhoods to check for
                    const sfNeighborhoods = SF_NEIGHBORHOODS;
                    
                    // First try to extract zip code and match to neighborhood
                    const zipCodeMatch = place.formatted_address.match(/\b(94\d{3})\b/);
                    if (zipCodeMatch && zipCodeMatch[1] && sfZipToNeighborhood[zipCodeMatch[1]]) {
                      neighborhood = sfZipToNeighborhood[zipCodeMatch[1]];
                    } else {
                      // If no zip code, fall back to looking for neighborhood names
                      const foundNeighborhood = addressParts.find(part => 
                        sfNeighborhoods.some(n => part.trim().includes(n))
                      );
                      
                      if (foundNeighborhood) {
                        // If we found a neighborhood, extract just that part
                        neighborhood = foundNeighborhood.trim();
                      } else {
                        // If no known neighborhood, try to find something that looks like one
                        // Check each part that isn't the street address or city/state/zip
                        for (let i = 1; i < addressParts.length - 1; i++) {
                          const part = addressParts[i].trim();
                          // Skip parts that are likely not neighborhoods
                          if (!part.includes("San Francisco") && 
                              !part.includes("CA") && 
                              !/^\d{5}$/.test(part) &&
                              part.length > 1) {
                            neighborhood = part;
                            break;
                          }
                        }
                      }
                    }
                    
                    description = `${readableTypes} in ${neighborhood}`;
                  }
                  
                  // Format address to remove ZIP code and "United States"
                  const formatAddress = (address) => {
                    // Split the address into parts
                    const parts = address.split(',');
                    
                    // Remove "United States" (usually the last part)
                    if (parts.length > 0 && parts[parts.length - 1].trim() === "United States") {
                      parts.pop();
                    }
                    
                    // Remove ZIP code (usually found in the second-to-last part with state)
                    if (parts.length > 1) {
                      // The state part usually looks like " CA 94110" or " California 94110"
                      const stateZipPart = parts[parts.length - 1].trim();
                      const statePart = stateZipPart.split(' ')[0]; // Keep just the state abbreviation
                      parts[parts.length - 1] = ` ${statePart}`; // Replace with just the state
                    }
                    
                    // Join parts back
                    return parts.join(',');
                  };

                  const content = `
                    <div style="padding: 8px;">
                      <h3 style="margin: 0 0 4px 0; color: #333;">Selected Location</h3>
                      <p style="margin: 0 0 4px 0; color: #9333ea; font-size: 12px;">
                        ${description}
                      </p>
                      <p style="margin: 0; color: #666; font-size: 14px;">${formatAddress(place.formatted_address)}</p>
                    </div>
                  `;
                  infoWindow.setContent(content);
                  infoWindow.open(map, newMarker);

                  setMarker(newMarker);

                  // Update input value with name and short address
                  const shortAddress = place.formatted_address.split(',')[0];
                  setCurrentInput(`${place.name || shortAddress} - ${shortAddress}`);

                  // After creating the infoWindow content and showing it
                  // Store the selected location for later restoration
                  setSelectedLocation({
                    location: place.geometry.location,
                    infoContent: content,
                    place: place
                  });
                });
              } else {
                console.error('Geocoding failed:', status);
              }
            });
          }
        });

        setAutocomplete(newAutocomplete);
      } catch (error) {
        console.error('Error initializing Places Autocomplete:', error);
        // Fallback to basic text input if Places API fails
        const input = autocompleteInputRef.current;
        if (input) {
          input.placeholder = "Enter a location in San Francisco";
          input.onchange = (e) => setCurrentInput(e.target.value);
        }
      }
    }
    
    // Capture the current ref value inside the effect to use in cleanup
    const currentInputRef = autocompleteInputRef.current;
    
    // Cleanup function to remove autocomplete when component unmounts
    return () => {
      if (currentInputRef) {
        window.google?.maps?.event?.clearInstanceListeners?.(currentInputRef);
      }
      // Remove any Google autocomplete containers
      const existingAutocompleteContainers = document.querySelectorAll('.pac-container');
      existingAutocompleteContainers.forEach(container => {
        container.remove();
      });
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, currentQuestion]);

  // Cleanup function for marker
  useEffect(() => {
    return () => {
      if (marker) {
        marker.map = null;
      }
    };
  }, [marker]);

  const handleAnswer = (answer) => {
    const newAnswers = { ...answers, [questions[currentQuestion].id]: answer };
    
    // If this is the location question and we have a selected location with description
    if (currentQuestion === 1 && selectedLocation && selectedLocation.place) {
      // Get description from editorial_summary or generate one from types
      let description = '';
      if (selectedLocation.place.editorial_summary && selectedLocation.place.editorial_summary.overview) {
        description = selectedLocation.place.editorial_summary.overview;
      } else if (selectedLocation.place.types && selectedLocation.place.types.length > 0) {
        // Convert first type to readable format
        const readableTypes = selectedLocation.place.types
          .slice(0, 1)
          .map(type => type.replace(/_/g, ' '))
          .join('');
        
        // Extract neighborhood
        let neighborhood = 'San Francisco';
        // Use the neighborhood already extracted for the marker
        const addressParts = selectedLocation.place.formatted_address.split(',');
        
        // Map of SF zip codes to neighborhoods - use the global constant
        const sfZipToNeighborhood = SF_ZIP_TO_NEIGHBORHOOD;
        
        // First try to extract zip code and match to neighborhood
        const zipCodeMatch = selectedLocation.place.formatted_address.match(/\b(94\d{3})\b/);
        if (zipCodeMatch && zipCodeMatch[1] && sfZipToNeighborhood[zipCodeMatch[1]]) {
          neighborhood = sfZipToNeighborhood[zipCodeMatch[1]];
        } else {
          // If no zip code, try to extract from the infoContent
          const descriptionMatch = selectedLocation.infoContent.match(/in ([^<]+)</);
          if (descriptionMatch && descriptionMatch[1]) {
            neighborhood = descriptionMatch[1];
          } else {
            // If no match from infoContent, try to find from address parts
            // List of known San Francisco neighborhoods to check for
            const sfNeighborhoods = SF_NEIGHBORHOODS;
            
            // Look for known neighborhood names in the address parts
            const foundNeighborhood = addressParts.find(part => 
              sfNeighborhoods.some(n => part.trim().includes(n))
            );
            
            if (foundNeighborhood) {
              // If we found a neighborhood, extract just that part
              neighborhood = foundNeighborhood.trim();
            } else {
              // If no known neighborhood, try to find something that looks like one
              // Check each part that isn't the street address or city/state/zip
              for (let i = 1; i < addressParts.length - 1; i++) {
                const part = addressParts[i].trim();
                // Skip parts that are likely not neighborhoods
                if (!part.includes("San Francisco") && 
                    !part.includes("CA") && 
                    !/^\d{5}$/.test(part) &&
                    part.length > 1) {
                  neighborhood = part;
                  break;
                }
              }
            }
          }
        }
        
        description = `${readableTypes} in ${neighborhood}`;
      }

      // Store location info for summary
      newAnswers.locationDesc = description;
      
      // Format address to remove ZIP code and "United States"
      const formatAddress = (address) => {
        // Split the address into parts
        const parts = address.split(',');
        
        // Remove "United States" (usually the last part)
        if (parts.length > 0 && parts[parts.length - 1].trim() === "United States") {
          parts.pop();
        }
        
        // Remove ZIP code (usually found in the second-to-last part with state)
        if (parts.length > 1) {
          // The state part usually looks like " CA 94110" or " California 94110"
          const stateZipPart = parts[parts.length - 1].trim();
          const statePart = stateZipPart.split(' ')[0]; // Keep just the state abbreviation
          parts[parts.length - 1] = ` ${statePart}`; // Replace with just the state
        }
        
        // Join parts back
        return parts.join(',');
      };
      
      // Check if this place exists in our database
      const matchingSpot = findMatchingSpot(selectedLocation.place);
      
      // Store more details for richer summary display
      newAnswers.locationDetails = {
        name: selectedLocation.place.name,
        formattedAddress: formatAddress(selectedLocation.place.formatted_address),
        description: description,
        photoUrl: selectedLocation.place.photos && selectedLocation.place.photos[0] ? 
          selectedLocation.place.photos[0].getUrl({ 
            maxWidth: 800,
            maxHeight: 500,
            crop: 'center',
            quality: 'highest'
          }) : null
      };
      
      // Add highlights and special notes from our database if we have a match
      if (matchingSpot) {
        newAnswers.locationDetails.highlights = matchingSpot.highlights || null;
        newAnswers.locationDetails.specialNotes = matchingSpot.specialNotes || null;
        
        // Store database hours as a fallback, but prioritize Google Places API
        if (matchingSpot.hours) {
          newAnswers.locationDetails.databaseHours = matchingSpot.hours;
        }
      }
      
      // Always try to get hours from Google Places API if available, regardless of whether 
      // we have a matching spot in our database or not
      const service = new window.google.maps.places.PlacesService(map);
      if (selectedLocation.place.place_id) {
        service.getDetails({
          placeId: selectedLocation.place.place_id,
          fields: ['opening_hours']
        }, (place, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK && 
              place && place.opening_hours && place.opening_hours.weekday_text) {
            // We've fetched hours, but the user might have already proceeded
            // Update the stored answers asynchronously
            const formattedHours = place.opening_hours.weekday_text.join(', ');
            setAnswers(current => {
              if (current.locationDetails) {
                return {
                  ...current,
                  locationDetails: {
                    ...current.locationDetails,
                    hours: formattedHours
                  }
                };
              }
              return current;
            });
          } else if (newAnswers.locationDetails.databaseHours) {
            // If Google Places API failed and we have hours from our database, use those
            setAnswers(current => {
              if (current.locationDetails) {
                return {
                  ...current,
                  locationDetails: {
                    ...current.locationDetails,
                    hours: newAnswers.locationDetails.databaseHours
                  }
                };
              }
              return current;
            });
          }
          
          // Clean up the temporary databaseHours property
          setAnswers(current => {
            if (current.locationDetails && current.locationDetails.databaseHours) {
              const { databaseHours, ...rest } = current.locationDetails;
              return {
                ...current,
                locationDetails: rest
              };
            }
            return current;
          });
        });
      } else if (matchingSpot && matchingSpot.hours) {
        // If no place_id (can't use Places API) but we have database hours, use those
        newAnswers.locationDetails.hours = matchingSpot.hours;
      }
    }
    
    setAnswers(newAnswers);

    if (currentQuestion < questions.length - 1) {
      const nextQuestion = currentQuestion + 1;
      setCurrentQuestion(nextQuestion);
      
      // Check if there's an existing answer for the next question and restore it
      const nextQuestionId = questions[nextQuestion].id;
      const nextAnswer = newAnswers[nextQuestionId] || '';
      setCurrentInput(nextAnswer);
    } else {
      setShowSummary(true);
    }
  };

  const handleBack = () => {
    if (currentQuestion > 0) {
      // Save the current input before navigating away
      if (currentQuestion === 2 && currentInput.trim()) {
        // We're on the qualifier screen, explicitly save before going back
        const qualifierId = questions[2].id;
        setAnswers(prev => ({
          ...prev,
          [qualifierId]: currentInput.trim()
        }));
      }
      
      // No need to hide the map as we'll recreate it when we come back
      const prevQuestion = currentQuestion - 1;
      setCurrentQuestion(prevQuestion);
      
      // Explicitly restore the answer for the previous question
      const prevQuestionId = questions[prevQuestion].id;
      const prevAnswer = answers[prevQuestionId] || '';
      setCurrentInput(prevAnswer);
    }
  };

  // Add a new function to handle going back from summary to edit
  const handleBackFromSummary = () => {
    setShowSummary(false);
    setCurrentQuestion(questions.length - 1);
    setCurrentInput(answers[questions[questions.length - 1].id] || '');
  };

  const handleTextInput = (event) => {
    const newValue = event.target.value;
    setCurrentInput(newValue);
    
    // Don't save input on every keystroke for map input to avoid rerendering
    // Only save for the text-type questions (e.g., qualifier)
    if (currentQuestion >= 0 && 
        currentQuestion < questions.length && 
        questions[currentQuestion].type === 'text') {
      const questionId = questions[currentQuestion].id;
      setAnswers(prev => ({
        ...prev,
        [questionId]: newValue
      }));
    }
    
    if (event.key === 'Enter' && event.target.value.trim()) {
      handleAnswer(event.target.value.trim());
    }
  };

  const handleNext = () => {
    if (questions[currentQuestion].type === 'text' && currentInput.trim()) {
      handleAnswer(currentInput.trim());
    } else if (questions[currentQuestion].type === 'map' && currentInput.trim()) {
      handleAnswer(currentInput.trim());
    } else if (currentInput.trim()) {
      // If we have input but didn't handle it above, at least store it temporarily
      // This ensures values are preserved during navigation
      const questionId = questions[currentQuestion].id;
      setAnswers(prev => ({
        ...prev,
        [questionId]: currentInput.trim()
      }));
    }
  };

  const generateSharedLink = async () => {
    try {
      setShareLoading(true);
      
      // Create a base location details object without undefined values
      let locationDetailsObj = null;
      
      if (selectedLocation) {
        locationDetailsObj = {
          name: selectedLocation.place?.name || answers.location,
          description: selectedLocation.place?.editorial_summary?.overview || selectedLocation.place?.types?.[0] || answers.locationDesc || 'Indoor rock climbing gym in the Mission District',
          formattedAddress: selectedLocation.place?.formatted_address || '2295 Harrison St, San Francisco, CA',
          coordinates: {
            lat: selectedLocation.location?.lat() || 37.7749,
            lng: selectedLocation.location?.lng() || -122.4194
          },
          highlights: selectedLocation.highlights || [
            "45-foot walls with routes for all skill levels",
            "Day passes available with gear rental for spontaneous dates",
            "Supportive environment perfect for trying something new together"
          ],
          notes: selectedLocation.notes || "Outdoor venue, free admission. Limited parking available. The north end is clothing-optional. Accessible by MUNI 29 bus. Strong currents make swimming unsafe, but perfect for romantic shoreline walks. Bring a blanket and picnic."
        };
        
        // Only add photoUrl if it exists
        if (selectedLocation.place?.photos?.[0]?.getUrl) {
          locationDetailsObj.photoUrl = selectedLocation.place.photos[0].getUrl({ maxWidth: 800, maxHeight: 400 });
        }
        
        // Only add placeId if it exists
        if (selectedLocation.place?.place_id) {
          locationDetailsObj.placeId = selectedLocation.place.place_id;
        }
        
        // Add opening hours from Google Places API if available
        if (selectedLocation.place?.opening_hours?.weekday_text) {
          // Store full weekday_text array for formatted display
          locationDetailsObj.hours = selectedLocation.place.opening_hours.weekday_text.join(', ');
        } else if (selectedLocation.place?.opening_hours?.isOpen) {
          // If we only have open/closed status but no specific hours
          locationDetailsObj.hours = selectedLocation.place.opening_hours.isOpen() ? 
            "Open now" : "Closed now";
        } else if (selectedLocation.place?.business_status === "OPERATIONAL") {
          // If we have no specific hours but the business is operational
          locationDetailsObj.hours = "6:00 AM-10:00 PM daily";
        }
      }
      
      // Collect all mission data
      const missionData = {
        mission_type: answers.mission_type,
        location: answers.location,
        qualifier: answers.qualifier,
        created_at: new Date(),
        
        // Add location details only if we have them
        locationDetails: locationDetailsObj,
        
        // Save the mission challenge and protocol details
        missionChallenge: missionChallenges.challenge || "Designed uniquely for each couple by LUV and revealed in person. Successful completion earns rewards to redeem at your future date together!",
        missionProtocol: missionChallenges.protocol || answers.qualifier
      };
      
      // Add to Firestore
      const docRef = await addDoc(collection(db, 'missions'), missionData);
      
      // Create shareable URL with mission ID
      const baseURL = window.location.origin;
      const shareableURL = `${baseURL}/mission/${docRef.id}`;
      
      setShareURL(shareableURL);
      setShareLoading(false);
      
      return shareableURL;
    } catch (error) {
      console.error('Error generating shared link:', error);
      setShareLoading(false);
      return null;
    }
  };

  const handleShare = async () => {
    // Generate or use existing share URL
    const url = shareURL || await generateSharedLink();
    
    if (!url) {
      alert('Sorry, there was a problem creating your sharing link. Please try again.');
      return;
    }
    
    const summary = `Check out my LUV mission in San Francisco! ${url}`;
    
    if (navigator.share) {
      navigator.share({
        title: 'My LUV Mission',
        text: summary,
        url: url
      }).catch(console.error);
    } else {
      navigator.clipboard.writeText(summary)
        .then(() => alert('Mission link copied to clipboard!'))
        .catch(console.error);
    }
  };

  // First trigger the onFindMatch callback to prepare the next survey
  // but don't close this survey until the next one is ready to display
  const handleFindMatchClick = () => {
    // Store the current state in localStorage before opening the new survey
    // This will ensure we return to the survey when closing the gender survey
    localStorage.setItem('luvMissionCurrentQuestion', currentQuestion);
    localStorage.setItem('luvMissionAnswers', JSON.stringify(answers));
    localStorage.setItem('luvMissionInput', currentInput);
    localStorage.setItem('luvMissionFromRecommendation', 'true');
    
    // Add a transition class to fade out current survey
    const surveyContainer = document.querySelector('.survey-container');
    const surveyOverlay = document.querySelector('.survey-overlay:not(.higher-survey)');
    
    if (surveyContainer && surveyOverlay) {
      // Add slide-out animation
      surveyOverlay.classList.add('slide-out-top');
      
      // Use better transition timing
      surveyContainer.style.transition = 'opacity 0.35s cubic-bezier(0.47, 0, 0.745, 0.715), transform 0.35s cubic-bezier(0.47, 0, 0.745, 0.715)';
      surveyContainer.classList.add('fade-out');
      
      // Apply coordinated animation
      surveyOverlay.style.opacity = '0';
      surveyOverlay.style.transform = 'translateY(-15px)';
      
      // Give a moment for the animation to start, then trigger the callback
      // This timing helps ensure a smooth transition between surveys
      setTimeout(() => {
        onFindMatch();
      }, 50);
    } else {
      // Fallback if container not found
      onFindMatch();
    }
  };

  const handleTrendingClick = () => {
    if (!map) return;
    
    setIsLoadingTrending(true);
    
    // Select a random location from our sfDateSpots library
    const randomIndex = Math.floor(Math.random() * sfDateSpots.length);
    const selectedSpot = sfDateSpots[randomIndex];
    
    // We need to geocode the address to get coordinates
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ address: selectedSpot.address }, (results, status) => {
      if (status === 'OK' && results[0]) {
        // Get location coordinates
        const location = results[0].geometry.location;
        
        // Update the input field with the place name and short address
        const shortAddress = selectedSpot.address.split(',')[0];
        setCurrentInput(`${selectedSpot.name} - ${shortAddress}`);

        // Update map
        map.setCenter(location);
        map.setZoom(15);

        // Remove existing marker if there is one
        if (marker) {
          marker.map = null;
        }

        // Create new marker
        const markerView = document.createElement('div');
        markerView.style.width = '30px';
        markerView.style.height = '30px';
        markerView.style.backgroundColor = '#ff69b4';
        markerView.style.borderRadius = '50%';
        markerView.style.border = '3px solid white';
        markerView.style.boxShadow = '0 0 15px rgba(147, 51, 234, 0.5), 0 2px 4px rgba(0,0,0,0.2)';
        markerView.style.zIndex = '1000';
        markerView.style.position = 'relative';
        markerView.style.cursor = 'pointer';

        window.google.maps.importLibrary("marker").then(({ AdvancedMarkerElement }) => {
          const newMarker = new AdvancedMarkerElement({
            map: map,
            position: location,
            content: markerView,
            zIndex: 1000
          });

          // Create a place object similar to what the Google Places API would return
          // so we can use it with our existing code
          const placeData = {
            name: selectedSpot.name,
            formatted_address: selectedSpot.address,
            geometry: {
              location: location
            }
          };

          // Use Google's Places Service to try to find a photo for this location
          const placesService = new window.google.maps.places.PlacesService(map);
          placesService.findPlaceFromQuery({
            query: `${selectedSpot.name} ${selectedSpot.address.split(',')[0]}`,
            fields: ['photos', 'place_id']
          }, (results, status) => {
            let photoUrl = null;
            
            // If we found a matching place with photos, use it
            if (status === window.google.maps.places.PlacesServiceStatus.OK && 
                results && results[0] && results[0].photos && results[0].photos[0]) {
              photoUrl = results[0].photos[0].getUrl({ 
                maxWidth: 400,
                maxHeight: 200,
                crop: 'center',
                quality: 'high'
              });
            }
            
            // Format address to remove ZIP code and "United States"
            const formatAddress = (address) => {
              // Split the address into parts
              const parts = address.split(',');
              
              // Remove "United States" (usually the last part)
              if (parts.length > 0 && parts[parts.length - 1].trim() === "United States") {
                parts.pop();
              }
              
              // Remove ZIP code (usually found in the second-to-last part with state)
              if (parts.length > 1) {
                // The state part usually looks like " CA 94110" or " California 94110"
                const stateZipPart = parts[parts.length - 1].trim();
                const statePart = stateZipPart.split(' ')[0]; // Keep just the state abbreviation
                parts[parts.length - 1] = ` ${statePart}`; // Replace with just the state
              }
              
              // Join parts back
              return parts.join(',');
            };

            // Create the info window content
            const content = `
              <div style="padding: 8px;">
                ${photoUrl ? `
                  <div style="margin-bottom: 8px; border-radius: 4px; overflow: hidden; display: flex; justify-content: center;">
                    <img 
                      src="${photoUrl}" 
                      alt="${selectedSpot.name}"
                      style="width: 50%; height: 60px; object-fit: cover;"
                    />
                  </div>
                ` : ''}
                <h3 style="margin: 0 0 4px 0; color: #333;">${selectedSpot.name}</h3>
                <p style="margin: 0 0 4px 0; color: #9333ea; font-size: 12px;">
                  ${selectedSpot.locationDescription}
                </p>
                <p style="margin: 0; color: #666; font-size: 14px;">${formatAddress(selectedSpot.address)}</p>
                ${selectedSpot.highlights && selectedSpot.highlights.length > 0 ? `
                  <p style="margin: 4px 0 0 0; color: #444; font-size: 12px; font-style: italic;">
                    "${selectedSpot.highlights[0]}"
                  </p>
                ` : ''}
              </div>
            `;
            
            // Add click listener to the marker for toggling info window
            markerView.addEventListener('click', () => {
              if (infoWindow.getMap()) {
                infoWindow.close();
              } else {
                infoWindow.setContent(content);
                infoWindow.open(map, newMarker);
              }
            });

            // Show info window automatically
            infoWindow.setContent(content);
            infoWindow.open(map, newMarker);

            setMarker(newMarker);
            setIsLoadingTrending(false);

            // After creating the infoWindow content and showing it
            // Store the selected location for later restoration
            setSelectedLocation({
              location: location,
              infoContent: content,
              place: {
                ...placeData,
                editorial_summary: { overview: selectedSpot.locationDescription },
                types: [selectedSpot.locationDescription.split(' ')[0]],
                photos: photoUrl ? [{ getUrl: (opts) => photoUrl }] : null
              }
            });
            
            // Store additional information from our database for the summary display
            // This prepares the data that will be used when the user proceeds to the summary
            const formattedAddress = formatAddress(selectedSpot.address);
            setAnswers(current => ({
              ...current,
              locationDetails: {
                name: selectedSpot.name,
                formattedAddress: formattedAddress,
                description: selectedSpot.locationDescription,
                photoUrl: photoUrl,
                highlights: selectedSpot.highlights || null,
                specialNotes: selectedSpot.specialNotes || null
              }
            }));
            
            // If we found a place_id from the search, attempt to get current hours from Google
            if (results && results[0] && results[0].place_id) {
              placesService.getDetails({
                placeId: results[0].place_id,
                fields: ['opening_hours']
              }, (detailedPlace, detailsStatus) => {
                if (detailsStatus === window.google.maps.places.PlacesServiceStatus.OK &&
                    detailedPlace && detailedPlace.opening_hours && detailedPlace.opening_hours.weekday_text) {
                  // Update with fresh hours from Google
                  const formattedHours = detailedPlace.opening_hours.weekday_text.join(', ');
                  setAnswers(current => {
                    if (current.locationDetails) {
                      return {
                        ...current,
                        locationDetails: {
                          ...current.locationDetails,
                          hours: formattedHours
                        }
                      };
                    }
                    return current;
                  });
                } else if (selectedSpot.hours) {
                  // Fall back to database hours if Google API failed
                  setAnswers(current => {
                    if (current.locationDetails) {
                      return {
                        ...current,
                        locationDetails: {
                          ...current.locationDetails,
                          hours: selectedSpot.hours
                        }
                      };
                    }
                    return current;
                  });
                }
              });
            } else if (selectedSpot.hours) {
              // If we couldn't get a place_id, use database hours as fallback
              setAnswers(current => {
                if (current.locationDetails) {
                  return {
                    ...current,
                    locationDetails: {
                      ...current.locationDetails,
                      hours: selectedSpot.hours
                    }
                  };
                }
                return current;
              });
            }
          });
        });
      } else {
        console.error('Geocoding failed:', status);
        setIsLoadingTrending(false);
      }
    });
  };

  const handleSuggestProtocol = () => {
    setIsLoadingProtocol(true);
    
    // Simulate loading for better UX
    setTimeout(() => {
      // Pick a random protocol from the array
      const randomIndex = Math.floor(Math.random() * protocolChallengePairs.length);
      const selectedPair = protocolChallengePairs[randomIndex];
      
      // Update the input field with the protocol
      setCurrentInput(selectedPair.protocol);
      
      // Store the associated challenge for later use
      setMissionChallenges({
        ...missionChallenges,
        [selectedPair.protocol]: selectedPair.challenge
      });
      
      setIsLoadingProtocol(false);
    }, 500);
  };

  const renderQuestionContent = () => {
    const question = questions[currentQuestion];

    switch (question.type) {
      case 'map':
        return (
          <div className="map-container">
            <div className="map-navigation">
              <button 
                className={`survey-back ${currentQuestion > 0 ? 'visible' : ''}`}
                onClick={handleBack}
                aria-label="Go back to previous question"
              >
                ‹
              </button>
              <input
                type="text"
                className="survey-input"
                placeholder={question.placeholder}
                value={currentInput}
                onChange={(e) => {
                  // Directly update the input value without recreating the element
                  setCurrentInput(e.target.value);
                }}
                ref={autocompleteInputRef}
                key="autocomplete-input" // Keep the same key to preserve element identity
                onKeyPress={(e) => {
                  // Get the flag from the ref's property
                  const isInAutocompleteSelection = window.isSelectingAutocomplete;
                  
                  // Only advance to next question if both:
                  // 1. Enter key was pressed
                  // 2. We're not in the middle of selecting an autocomplete suggestion
                  if (e.key === 'Enter' && currentInput.trim() && !isInAutocompleteSelection) {
                    handleAnswer(currentInput.trim());
                  }
                }}
              />
              <button
                className="survey-next"
                onClick={handleNext}
                disabled={isNextDisabled()}
                aria-label="Next question"
              >
                ›
              </button>
            </div>
            <div id="map" className="map" ref={mapRef} style={{ marginBottom: '8px' }}></div>
            
            <button 
              className="trending-button"
              onClick={handleTrendingClick}
              disabled={isLoadingTrending}
              style={{ width: '240px', maxWidth: '80%', margin: '1px auto' }}
            >
              {isLoadingTrending ? 'Finding a place...' : 'Pick a place for me!'}
            </button>
            
            {/* Add the signup link at the bottom of the map question */}
            <p 
              onClick={() => {
                // Store the current state in localStorage before opening the new survey
                localStorage.setItem('luvMissionCurrentQuestion', currentQuestion);
                localStorage.setItem('luvMissionAnswers', JSON.stringify(answers));
                localStorage.setItem('luvMissionInput', currentInput);
                // Use a flag to indicate this was from the recommendation link
                localStorage.setItem('luvMissionFromRecommendation', 'true');
                
                // Prepare current survey for transition with improved animation
                const currentSurvey = document.querySelector('.survey-overlay:not(.higher-survey)');
                if (currentSurvey) {
                  // Apply slide-out animation before handling redirect
                  currentSurvey.classList.add('slide-out-top');
                  currentSurvey.style.opacity = '0';
                  currentSurvey.style.transform = 'translateY(-15px)';
                  
                  // Also animate the container for consistency
                  const container = currentSurvey.querySelector('.survey-container');
                  if (container) {
                    container.classList.add('fade-out');
                  }
                }
                
                // Call the find match handler with slight delay to allow animation to start
                setTimeout(() => {
                  handleFindMatchClick();
                }, 50);
              }}
              style={{ 
                color: '#9370DB', 
                textDecoration: 'underline', 
                cursor: 'pointer', 
                fontSize: '14px',
                marginTop: '0',
                marginBottom: '0',
                textAlign: 'center',
                width: '100%',
                display: 'block'
              }}
            >
              Sign up to receive a personalized recommendation
            </p>
          </div>
        );

      case 'text':
        return (
          <>
            <input
              type="text"
              className="survey-input"
              placeholder={question.placeholder}
              value={currentInput}
              onChange={handleTextInput}
              onKeyPress={(e) => {
                // For text inputs, we don't need to check the autocomplete flag since there's no autocomplete
                if (e.key === 'Enter' && currentInput.trim()) {
                  handleAnswer(currentInput.trim());
                }
              }}
            />
            {currentQuestion === 2 && (
              <>
                <p style={{ fontSize: '14px', color: '#666', marginTop: '8px', marginBottom: '12px', maxWidth: '80%', margin: '8px auto 12px' }}>
                  Something playful to prepare in advance that will be required to complete your mission.<br /> <br /> Make your date more intentional by putting in a small effort and sharing a playful moment.
                </p>
                <button 
                  className="trending-button"
                  onClick={handleSuggestProtocol}
                  disabled={isLoadingProtocol}
                  style={{ marginTop: '16px', width: '240px', maxWidth: '80%', margin: '16px auto 16px' }}
                >
                  {isLoadingProtocol ? 'Finding a qualifier...' : 'Suggest a qualifier!'}
                </button>
                <p 
                  onClick={() => {
                    // Store the current state in localStorage before opening the new survey
                    localStorage.setItem('luvMissionCurrentQuestion', currentQuestion);
                    localStorage.setItem('luvMissionAnswers', JSON.stringify(answers));
                    localStorage.setItem('luvMissionInput', currentInput);
                    // Use a flag to indicate this was from the recommendation link
                    localStorage.setItem('luvMissionFromRecommendation', 'true');
                    
                    // Prepare current survey for transition with improved animation
                    const currentSurvey = document.querySelector('.survey-overlay:not(.higher-survey)');
                    if (currentSurvey) {
                      // Apply slide-out animation before handling redirect
                      currentSurvey.classList.add('slide-out-top');
                      currentSurvey.style.opacity = '0';
                      currentSurvey.style.transform = 'translateY(-15px)';
                      
                      // Also animate the container for consistency
                      const container = currentSurvey.querySelector('.survey-container');
                      if (container) {
                        container.classList.add('fade-out');
                      }
                    }
                    
                    // Call the find match handler with slight delay to allow animation to start
                    setTimeout(() => {
                      handleFindMatchClick();
                    }, 50);
                  }}
                  style={{ 
                    color: '#9370DB', 
                    textDecoration: 'underline', 
                    cursor: 'pointer', 
                    fontSize: '14px',
                    marginTop: '10px',
                    marginBottom: '0',
                    paddingBottom: '0'
                  }}
                >
                  Sign up to receive a personalized recommendation
                </p>
              </>
            )}
          </>
        );

      default:
        // First question (mission_type) with standardized navigation pattern
        if (currentQuestion === 0) {
          return (
            <div className="survey-options first-question-options">
              {question.options.map((option, index) => {
                // Define button styles based on index
                let buttonStyle = {};
                if (index === 0) {
                  buttonStyle = {
                    backgroundColor: '#ff1493',
                    color: 'white',
                    borderColor: '#ff1493'
                  };
                } else if (index === 1) {
                  buttonStyle = {
                    backgroundColor: '#9333ea',
                    color: 'white',
                    borderColor: '#9333ea'
                  };
                } else if (index === 2) {
                  buttonStyle = {
                    backgroundColor: '#000000',
                    color: 'white',
                    borderColor: '#000000'
                  };
                }
                
                return (
                  <button
                    key={index}
                    className={`survey-option ${currentInput === option ? 'selected' : ''}`}
                    style={{
                      ...buttonStyle,
                      ...(currentInput === option ? {
                        boxShadow: '0 0 0 3px white, 0 0 0 6px ' + (buttonStyle.borderColor || '#9333ea'),
                        transform: 'scale(1.05)',
                        transition: 'all 0.2s ease'
                      } : {})
                    }}
                    onClick={() => {
                      setCurrentInput(option);
                      // Auto-advance with a faster delay for quicker feedback
                      setTimeout(() => handleAnswer(option), 75);
                    }}
                  >
                    {option}
                  </button>
                );
              })}
            </div>
          );
        }
        
        // Default rendering for other single-choice questions
        return (
          <div className="survey-options">
            {question.options.map((option, index) => (
              <button
                key={index}
                className="survey-option"
                onClick={() => handleAnswer(option)}
              >
                {option}
              </button>
            ))}
          </div>
        );
    }
  };

  const shouldShowNext = () => {
    return questions[currentQuestion].type === 'text' || questions[currentQuestion].type === 'map';
  };

  const isNextDisabled = () => {
    return (questions[currentQuestion].type === 'text' || questions[currentQuestion].type === 'map') && !currentInput.trim();
  };

  // Function to handle when "TRY" is clicked in the Learn More popup
  const handleLearnMoreTry = () => {
    if (onLearnMoreClose) {
      onLearnMoreClose();
    }
  };

  // Check for saved state when component mounts
  useEffect(() => {
    const savedFromRecommendation = localStorage.getItem('luvMissionFromRecommendation');
    
    if (savedFromRecommendation === 'true') {
      // Make sure the survey is visible if it was hidden
      const surveyOverlay = document.querySelector('.survey-overlay');
      if (surveyOverlay) {
        surveyOverlay.style.display = 'flex';
      }
      
      // Don't clear the flag immediately to allow parent component to check it
      // Instead, we'll wait to ensure we've properly restored before clearing
      
      const savedQuestion = localStorage.getItem('luvMissionCurrentQuestion');
      const savedAnswers = localStorage.getItem('luvMissionAnswers');
      const savedInput = localStorage.getItem('luvMissionInput');
      
      if (savedQuestion && savedAnswers) {
        setCurrentQuestion(parseInt(savedQuestion, 10));
        setAnswers(JSON.parse(savedAnswers));
        if (savedInput) {
          setCurrentInput(savedInput);
        }
        
        // Only clear the recommendation flag after we've successfully restored
        // Use setTimeout to ensure state updates have been applied
        setTimeout(() => {
          localStorage.removeItem('luvMissionFromRecommendation');
        }, 100);
      }
    }
  }, []);

  // Add scroll event listener to the mission details container to hide the scroll indicator
  useEffect(() => {
    if (showSummary && missionDetailsRef.current) {
      const handleScroll = () => {
        setShowScrollIndicator(false);
      };
      
      const missionDetailsElement = missionDetailsRef.current;
      missionDetailsElement.addEventListener('scroll', handleScroll, { passive: true });
      
      // Check if content is actually scrollable
      const isScrollable = missionDetailsElement.scrollHeight > missionDetailsElement.clientHeight;
      setShowScrollIndicator(isScrollable);
      
      return () => {
        missionDetailsElement.removeEventListener('scroll', handleScroll);
      };
    }
  }, [showSummary]);

  // Function to find if a Google place matches one in our database
  const findMatchingSpot = (googlePlace) => {
    if (!googlePlace || !googlePlace.name) return null;
    
    // First, try to find an exact name match
    let match = sfDateSpots.find(spot => 
      spot.name.toLowerCase() === googlePlace.name.toLowerCase()
    );
    
    // If no exact match, try partial name match
    if (!match) {
      match = sfDateSpots.find(spot => 
        googlePlace.name.toLowerCase().includes(spot.name.toLowerCase()) || 
        spot.name.toLowerCase().includes(googlePlace.name.toLowerCase())
      );
    }
    
    // If still no match, try matching by address
    if (!match && googlePlace.formatted_address) {
      // Extract the first part of the address for comparison (usually street address)
      const googleAddressPart = googlePlace.formatted_address.split(',')[0].toLowerCase();
      
      match = sfDateSpots.find(spot => {
        const spotAddressPart = spot.address.split(',')[0].toLowerCase();
        return spotAddressPart === googleAddressPart || 
               googleAddressPart.includes(spotAddressPart) ||
               spotAddressPart.includes(googleAddressPart);
      });
    }
    
    return match;
  };

  return (
    <>
      <div className="survey-overlay">
        <div className="survey-container" style={{ paddingBottom: currentQuestion === 1 || currentQuestion === 2 ? '1.5rem' : null }}>
          <button className="survey-close" onClick={onClose}>×</button>
          
          {/* Modified condition to show navigation buttons on all questions except map question */}
          {!showSummary && currentQuestion !== 1 && (
            <>
              <button 
                className={`survey-back ${currentQuestion > 0 ? 'visible' : 'invisible'}`}
                onClick={handleBack}
                aria-label="Go back to previous question"
              >
                ‹
              </button>
              {(shouldShowNext() || currentQuestion === 0) && (
                <button
                  className="survey-next"
                  onClick={currentQuestion === 0 ? () => handleAnswer(currentInput) : handleNext}
                  disabled={(currentQuestion === 0 && !currentInput) || (currentQuestion !== 0 && isNextDisabled())}
                  aria-label="Next question"
                >
                  ›
                </button>
              )}
            </>
          )}
          
          {!showSummary && currentQuestion === 0 && (
            <div className="survey-header">
              <h1 style={{ marginBottom: '0.2rem' }}>
                Make your next <span className="highlight">date special</span>. Turn it into a <span className="highlight">LUV mission</span> and <span className="highlight">win rewards</span>! 
              </h1>
              <h1>
                If you don't have a date, we will <span className="highlight">help you</span> <span className="highlight">find</span> one.
              </h1>
            </div>
          )}
          
          {/* Learn More Popup - shown based on prop */}
          {showLearnMore && (
            <LearnMorePopup 
              onClose={onLearnMoreClose} 
              onTry={handleLearnMoreTry} 
            />
          )}
          
          <div className="survey-progress">
            <div 
              className="survey-progress-bar"
              style={{ width: showSummary ? '100%' : `${((currentQuestion + 1) / (questions.length + 1)) * 100}%` }}
            />
          </div>

          {!showSummary ? (
            <>
              <h2 className="survey-question">
                {questions[currentQuestion].question}
              </h2>

              {renderQuestionContent()}
              
              {currentQuestion === 0 && (
                <p style={{ color: '#666', fontSize: '0.9em', marginTop: '1rem', marginBottom: '0.2rem', textAlign: 'center' }}>
                  Currently limited to SF only.
                </p>
              )}
            </>
          ) : (
            <div className="survey-summary">
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative', marginBottom: '20px' }}>
                <button 
                  className="survey-back visible"
                  onClick={handleBackFromSummary}
                  aria-label="Go back to edit mission"
                  style={{ position: 'absolute', left: '-20px' }}
                >
                  ‹
                </button>
                <h2 
                  className="survey-question" 
                  style={{ 
                    margin: '0',
                    fontWeight: 'bold', 
                    color: 'rgba(147, 51, 234, 0.8)', 
                    textShadow: '0 0 5px rgba(147, 51, 234, 0.6), 0 0 15px rgba(147, 51, 234, 0.5), 0 0 20px rgba(147, 51, 234, 0.4)', 
                    letterSpacing: '1px'
                  }}
                >
                  Your LUV Mission in SF
                </h2>
              </div>
              <div 
                className="mission-details" 
                ref={missionDetailsRef}
                style={{ 
                  overflowY: 'auto', 
                  overflowX: 'hidden',
                  borderRadius: '8px',
                  textAlign: 'center'
                }}
              >
                {/* Add the scroll indicator at the top */}
                {showScrollIndicator && (
                  <div className="scroll-indicator" style={{
                    width: '100%',
                    textAlign: 'center',
                    display: 'block',
                  }}>↓  scroll for more  ↓</div>
                )}
                
                {answers.locationDetails ? (
                  <div className="location-info-card" style={{ marginBottom: '16px', borderRadius: '8px', border: '1px solid #eee', overflow: 'hidden', boxShadow: '0 2px 4px rgba(0,0,0,0.05)' }}>
                    {answers.locationDetails.photoUrl && (
                      <div style={{ paddingTop: '12px', marginBottom: '8px', overflow: 'hidden', display: 'flex', justifyContent: 'center' }}>
                        <img 
                          src={answers.locationDetails.photoUrl}
                          alt={answers.locationDetails.name || 'Location photo'} 
                          style={{ 
                            width: '50%', 
                            height: 'auto', 
                            aspectRatio: '2/1', 
                            objectFit: 'cover',
                            borderRadius: '8px' 
                          }}
                        />
                      </div>
                    )}
                    <div style={{ padding: '0 16px 16px' }}>
                      <h3 style={{ margin: '0 0 4px 0', color: '#333', fontSize: '20px' }}>{answers.locationDetails.name}</h3>
                      <p style={{ margin: '0 0 4px 0', color: '#9333ea', fontSize: '14px' }}>
                        {answers.locationDetails.description}
                      </p>
                      
                      <a 
                        href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(answers.locationDetails.name + ' ' + answers.locationDetails.formattedAddress)}`}
                        target="_blank" 
                        rel="noopener noreferrer"
                        style={{ 
                          display: 'flex', 
                          alignItems: 'center', 
                          color: '#333', 
                          textDecoration: 'none',
                          margin: '8px auto',
                          justifyContent: 'center',
                          maxWidth: '90%'
                        }}
                      >
                        <div style={{ 
                          display: 'inline-flex', 
                          backgroundColor: '#f9f0ff', 
                          borderRadius: '8px', 
                          padding: '6px', 
                          marginRight: '8px' 
                        }}>
                          <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z" fill="url(#pinGradient)"/>
                            <defs>
                              <linearGradient id="pinGradient" x1="5" y1="2" x2="19" y2="22" gradientUnits="userSpaceOnUse">
                                <stop offset="0%" stopColor="#ff1493"/>
                                <stop offset="100%" stopColor="#9333ea"/>
                              </linearGradient>
                            </defs>
                          </svg>
                        </div>
                        <div>
                          <div style={{ fontWeight: 'bold', fontSize: '16px', display: 'flex', alignItems: 'center' }}>
                            {answers.locationDetails.formattedAddress.split(',')[0]}
                            <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginLeft: '4px' }}>
                              <path d="M7 17L17 7M17 7H10M17 7V14" stroke="#666" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                          </div>
                          <div style={{ color: '#666', fontSize: '14px' }}>
                            {answers.locationDetails.formattedAddress.split(',').slice(1).join(',').trim()}
                          </div>
                        </div>
                      </a>
                      
                      {/* Display hours of operation if available */}
                      {answers.locationDetails.hours && (
                        <div style={{ 
                          textAlign: 'left', 
                          fontSize: '14px', 
                          margin: '12px 0 8px',
                          padding: '8px',
                          backgroundColor: '#f8f9fa',
                          borderRadius: '6px' 
                        }}>
                          <div style={{ fontWeight: 'bold', color: '#333', marginBottom: '4px' }}>
                            <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ verticalAlign: 'middle', marginRight: '4px' }}>
                              <circle cx="12" cy="12" r="9" stroke="#9333ea" strokeWidth="2"/>
                              <path d="M12 7v5l3 3" stroke="#9333ea" strokeWidth="2" strokeLinecap="round"/>
                            </svg>
                            Hours:
                          </div>
                          <div style={{ color: '#555' }}>{answers.locationDetails.hours}</div>
                        </div>
                      )}
                      
                      {/* Display highlights if available */}
                      {answers.locationDetails.highlights && answers.locationDetails.highlights.length > 0 && (
                        <div style={{ 
                          textAlign: 'left', 
                          fontSize: '14px', 
                          margin: '12px 0 8px',
                          padding: '8px',
                          backgroundColor: '#f9f0ff',
                          borderRadius: '6px' 
                        }}>
                          <div style={{ fontWeight: 'bold', color: '#333', marginBottom: '4px' }}>
                            <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ verticalAlign: 'middle', marginRight: '4px' }}>
                              <path d="M12 2L15.09 8.26L22 9.27L17 14.14L18.18 21.02L12 17.77L5.82 21.02L7 14.14L2 9.27L8.91 8.26L12 2Z" fill="#ff1493" fillOpacity="0.7"/>
                            </svg>
                            Highlights:
                          </div>
                          <ul style={{ 
                            listStyle: 'none', 
                            margin: '0', 
                            padding: '0', 
                            color: '#555' 
                          }}>
                            {answers.locationDetails.highlights.map((highlight, idx) => (
                              <li key={idx} style={{ 
                                padding: '2px 0',
                                display: 'flex',
                                alignItems: 'flex-start' 
                              }}>
                                <span style={{ color: '#9333ea', marginRight: '6px' }}>•</span>
                                {highlight}
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                      
                      {/* Display special notes if available */}
                      {answers.locationDetails.specialNotes && (
                        <div style={{ 
                          textAlign: 'left', 
                          fontSize: '14px', 
                          margin: '12px 0 8px',
                          padding: '8px',
                          backgroundColor: '#fff9db',
                          borderRadius: '6px' 
                        }}>
                          <div style={{ fontWeight: 'bold', color: '#333', marginBottom: '4px' }}>
                            <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ verticalAlign: 'middle', marginRight: '4px' }}>
                              <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#ff9500" strokeWidth="2"/>
                              <path d="M12 8v4" stroke="#ff9500" strokeWidth="2" strokeLinecap="round"/>
                              <circle cx="12" cy="16" r="1" fill="#ff9500"/>
                            </svg>
                            Notes:
                          </div>
                          <div style={{ color: '#555' }}>{answers.locationDetails.specialNotes}</div>
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <p style={{ textAlign: 'center' }}>
                    <strong>Mission Spot:</strong> 
                    <a 
                      href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(answers.location)}`}
                      target="_blank" 
                      rel="noopener noreferrer"
                      style={{ 
                        display: 'inline-flex', 
                        alignItems: 'center', 
                        color: '#333', 
                        textDecoration: 'none',
                        margin: '0 0 0 8px',
                        justifyContent: 'center'
                      }}
                    >
                      <div style={{ 
                        display: 'inline-flex', 
                        backgroundColor: '#f9f0ff', 
                        borderRadius: '8px', 
                        padding: '6px', 
                        marginRight: '8px' 
                      }}>
                        <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z" fill="url(#pinGradientSmall)"/>
                          <defs>
                            <linearGradient id="pinGradientSmall" x1="5" y1="2" x2="19" y2="22" gradientUnits="userSpaceOnUse">
                              <stop offset="0%" stopColor="#ff1493"/>
                              <stop offset="100%" stopColor="#9333ea"/>
                            </linearGradient>
                          </defs>
                        </svg>
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {answers.location}
                        <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginLeft: '4px' }}>
                          <path d="M7 17L17 7M17 7H10M17 7V14" stroke="#666" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                      </div>
                    </a>
                    {answers.locationDesc ? <span style={{ color: "#888" }}>({answers.locationDesc})</span> : ''}
                  </p>
                )}



                <div style={{
                  backgroundColor: '#f5eaff',
                  borderRadius: '12px',
                  padding: '16px 12px',
                  margin: '7px auto 7px',
                  maxWidth: '100%',
                  boxShadow: '0 3px 6px rgba(147, 51, 234, 0.15)',
                  border: '1px solid #e2cbff',
                  position: 'relative',
                  overflow: 'hidden'
                }}>
                  <div style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '5px',
                    height: '100%',
                    background: 'linear-gradient(to bottom, #9333ea, #ff1493)'
                  }}></div>
                  
                  <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginBottom: '10px'
                  }}>
               
                    <span style={{ 
                      fontSize: '18px', 
                      fontWeight: 'bold',
                      color: '#9333ea',
                      letterSpacing: '0.5px'
                    }}>
                      Mission Qualifier
                    </span>
                  </div>
                  
                  <p style={{
                    fontSize: '18px',
                    fontWeight: '500',
                    textAlign: 'center',
                    margin: '0 0 5px 0',
                    color: '#333'
                  }}>
                    {answers.qualifier}
                  </p>

                </div>
                
                <p style={{ 
                    fontSize: '13px', 
                    textAlign: 'center', 
                    margin: '5px 3px 15px 3px', 
                    color: '#666'
                  }}>
                    Required to complete your mission. You will receive a mission task when you meet in person. Bring this with you for a fun experience and rewards! </p>

                {/* Add sign up link below the hint text */}
                <p 
                  onClick={() => {
                    // Store the current state in localStorage before opening the new survey
                    localStorage.setItem('luvMissionCurrentQuestion', currentQuestion);
                    localStorage.setItem('luvMissionAnswers', JSON.stringify(answers));
                    localStorage.setItem('luvMissionInput', currentInput);
                    localStorage.setItem('luvMissionFromRecommendation', 'true');
                    
                    // Add animation transition
                    const surveyOverlay = document.querySelector('.survey-overlay');
                    const surveyContainer = document.querySelector('.survey-container');
                    
                    if (surveyOverlay && surveyContainer) {
                      surveyOverlay.classList.add('slide-out-top');
                      surveyContainer.classList.add('fade-out');
                      surveyOverlay.style.opacity = '0';
                      surveyOverlay.style.transform = 'translateY(-15px)';
                    }
                    
                    // Call find match handler with slight delay for animation
                    setTimeout(() => {
                      handleFindMatchClick();
                    }, 50);
                  }}
                  style={{ 
                    color: '#9370DB', 
                    textDecoration: 'underline', 
                    cursor: 'pointer', 
                    fontSize: '14px',
                    marginTop: '0',
                    marginBottom: '12px',
                    textAlign: 'center',
                    width: '100%',
                    display: 'block'
                  }}
                >
                  Sign up to receive your mission task and win rewards
                </p>
              </div>
              <div className="mission-actions">
                <button 
                  className="survey-option share-mission" 
                  onClick={handleShare}
                  disabled={shareLoading}
                  style={{
                    backgroundColor: '#ff1493',
                    color: 'white',
                    borderColor: '#ff1493',
                    fontWeight: 'bold'
                  }}
                >
                  {shareLoading ? 'Generating Link...' : shareURL ? 'Share Mission Link' : 'Share Mission'}
                </button>
                <button 
                  className="survey-option find-match" 
                  onClick={handleFindMatchClick}
                  style={{
                    backgroundColor: '#6b21a8',
                    color: 'white',
                    borderColor: '#6b21a8',
                    fontWeight: 'bold'
                  }}
                >
                  Find a LUV Match
                </button>
                {shareURL && (
                  <div className="mission-share-link">
                    <p>Your mission link:</p>
                    <div className="share-url-container">
                      <input 
                        type="text" 
                        value={shareURL} 
                        readOnly 
                        onClick={(e) => e.target.select()} 
                        className="share-url-input"
                      />
                      <button 
                        className="copy-url-button"
                        onClick={() => {
                          navigator.clipboard.writeText(shareURL);
                          alert('Link copied to clipboard!');
                        }}
                      >
                        Copy
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default LuvMissionSurvey; 