import React, { useState, useEffect, useRef } from 'react';
import './App.css';
import heartIconWebp from './heart2.webp';
import heartIconPng from './heart2.png';
import johnImageWebp from './john3.webp';
import johnImagePng from './john3.png';
import timImageWebp from './tim2.webp';
import timImagePng from './tim2.png';
import andytownImageWebp from './andytown.webp';
import andytownImageJpg from './andytown.jpg';
import sunsetImageWebp from './sunset2.webp';
import sunsetImageJpeg from './sunset2.jpeg';
import Survey from './components/Survey';
import LuvMissionSurvey, { LearnMorePopup } from './components/LuvMissionSurvey';
import { db } from './firebase';
import { doc, getDoc } from 'firebase/firestore';

function App() {
  const [showPopup, setShowPopup] = useState(false);
  const [showMatchPopup, setShowMatchPopup] = useState(false);
  const [showProfilesPopup, setShowProfilesPopup] = useState(false);
  const [showSurvey, setShowSurvey] = useState(false);
  const [showLuvMissionSurvey, setShowLuvMissionSurvey] = useState(false);
  const [showLearnMore, setShowLearnMore] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [showLaser, setShowLaser] = useState(false);
  const [sharedMission, setSharedMission] = useState(null);
  const [loadingMission, setLoadingMission] = useState(false);
  const [showScrollIndicator, setShowScrollIndicator] = useState(true);
  const laserContainerRef = useRef(null);
  const sharedMissionDetailsRef = useRef(null);

  // Check for shared mission in URL
  useEffect(() => {
    const checkForSharedMission = async () => {
      const path = window.location.pathname;
      const match = path.match(/^\/mission\/([a-zA-Z0-9]+)$/);
      
      if (match && match[1]) {
        const missionId = match[1];
        setLoadingMission(true);
        
        try {
          const missionDoc = await getDoc(doc(db, 'missions', missionId));
          
          if (missionDoc.exists()) {
            // Get mission data and handle potential legacy data format
            const missionData = missionDoc.data();
            
            // Handle legacy missions that might not have all the fields
            // by providing fallback values
            const processedMission = {
              id: missionDoc.id,
              ...missionData,
              
              // Ensure location details exists with expected fields
              locationDetails: missionData.locationDetails || {
                name: missionData.location || "Mission Location",
                description: missionData.locationDesc || "Your LUV mission location",
                formattedAddress: "San Francisco, CA",
                coordinates: { lat: 37.7749, lng: -122.4194 },
                notes: "Outdoor venue, free admission. Limited parking available. The north end is clothing-optional. Accessible by MUNI 29 bus. Strong currents make swimming unsafe, but perfect for romantic shoreline walks. Bring a blanket and picnic."
              },
              
              // Ensure protocol and challenge fields exist
              missionProtocol: missionData.missionProtocol || missionData.qualifier || "Complete your mission qualifier",
              missionChallenge: missionData.missionChallenge || "Designed uniquely for each couple by LUV and revealed in person."
            };
            
            setSharedMission(processedMission);
          } else {
            console.error('Mission not found');
          }
        } catch (error) {
          console.error('Error loading shared mission:', error);
        } finally {
          setLoadingMission(false);
        }
      }
    };
    
    checkForSharedMission();
  }, []);

  useEffect(() => {
    // Preload the popup heart image
    const img = new Image();
    img.src = heartIconWebp;
    const fallbackImg = new Image();
    fallbackImg.src = heartIconPng;
    img.onload = () => setImageLoaded(true);
    fallbackImg.onload = () => setImageLoaded(true);
    
    // Preload match popup images
    const preloadImages = [
      { webp: johnImageWebp, fallback: johnImagePng },
      { webp: timImageWebp, fallback: timImagePng },
      { webp: andytownImageWebp, fallback: andytownImageJpg },
      { webp: sunsetImageWebp, fallback: sunsetImageJpeg }
    ];
    preloadImages.forEach(({ webp, fallback }) => {
      const img = new Image();
      img.src = webp;
      const fallbackImg = new Image();
      fallbackImg.src = fallback;
    });
  }, []);

  const handleLogoClick = (e) => {
    e.stopPropagation();
    if (imageLoaded) {
      setShowLaser(true);
      
      // Create three glossy laser lines with different random directions
      if (laserContainerRef.current) {
        // Create a new group div for this set of lasers
        const laserGroup = document.createElement('div');
        laserGroup.className = 'laser-group';
        
        // Get the position of the logo button
        const logoButton = document.querySelector('.logo-button');
        const logoRect = logoButton.getBoundingClientRect();
        
        // Calculate the center of the logo
        const logoCenter = {
          x: logoRect.left + logoRect.width / 2,
          y: logoRect.top + logoRect.height / 2
        };
        
        // Create three lasers with different random angles
        for (let i = 0; i < 3; i++) {
          const laser = document.createElement('div');
          laser.className = 'laser';
          
          // Generate a random angle between 0 and 360 degrees
          const randomAngle = Math.random() * 360;
          
          // Position the laser at the center of the logo
          laser.style.left = `${logoCenter.x}px`;
          laser.style.top = `${logoCenter.y}px`;
          
          // Apply the random rotation
          laser.style.transform = `translateX(-50%) rotate(${randomAngle}deg)`;
          
          laserGroup.appendChild(laser);
        }
        
        laserContainerRef.current.appendChild(laserGroup);
        
        // Remove this group of lasers after animation completes
        setTimeout(() => {
          if (laserGroup && laserGroup.parentNode === laserContainerRef.current) {
            laserContainerRef.current.removeChild(laserGroup);
          }
          // Only hide laser container if no more laser groups exist
          if (laserContainerRef.current && laserContainerRef.current.children.length === 0) {
            setShowLaser(false);
          }
        }, 1000);
      }
    }
  };

  const handleClosePopup = () => {
    setShowPopup(false);
    setShowMatchPopup(false);
    setShowProfilesPopup(false);
  };

  const handleJoinClick = () => {
    setShowLuvMissionSurvey(true);
  };
  
  const handleSurveyClose = () => {
    // First, check if we need to show the LuvMissionSurvey again
    const shouldReturn = localStorage.getItem('luvMissionFromRecommendation');
    
    if (shouldReturn === 'true') {
      // Make sure the LuvMissionSurvey is visible immediately (no animations)
      const luvSurvey = document.querySelector('.survey-overlay:not(.higher-survey)');
      if (luvSurvey) {
        // Show the Luv Mission Survey again
        luvSurvey.style = ''; // Clear all inline styles
        luvSurvey.classList.remove('slide-out-top');
        luvSurvey.style.visibility = 'visible';
        luvSurvey.style.display = 'flex';
        luvSurvey.style.opacity = '1';
        luvSurvey.style.zIndex = '1001';
        luvSurvey.style.transform = 'none';
        
        // Also make sure the container is visible
        const container = luvSurvey.querySelector('.survey-container');
        if (container) {
          container.style = ''; // Clear all inline styles
          container.classList.remove('fade-out');
          container.style.opacity = '1';
          container.style.transform = 'none';
        }
      } else {
        // If not found, explicitly show it from React state
        console.log("Reactivating LuvMissionSurvey from state");
        setShowLuvMissionSurvey(true);
      }
    }
    
    // Immediately hide the gender survey
    setShowSurvey(false);
  };

  const handleLuvMissionSurveyClose = () => {
    setShowLuvMissionSurvey(false);
  };

  const handleLearnMoreClose = () => {
    setShowLearnMore(false);
  };

  const handleLearnMoreTry = () => {
    setShowLearnMore(false);
    setShowLuvMissionSurvey(true);
  };

  const handleFindMatch = () => {
    // Check if coming from recommendation link
    const fromRecommendation = localStorage.getItem('luvMissionFromRecommendation') === 'true';
    
    // Get references to both surveys (before setting state)
    const luvSurvey = document.querySelector('.survey-overlay:not(.higher-survey)');
    
    // First set up the transition on the current survey
    if (luvSurvey) {
      // Apply a nice slide-out animation
      luvSurvey.classList.add('slide-out-top');
      luvSurvey.style.opacity = '0';
      luvSurvey.style.transform = 'translateY(-15px)';
    }
    
    // Set state to show the new survey immediately - it will be stacked on top
    setShowSurvey(true);
    
    // After React updates the DOM with the new survey
    setTimeout(() => {
      // Get reference to new gender survey
      const genderSurvey = document.querySelector('.higher-survey');
      
      if (genderSurvey) {
        // Set initial state for animation
        genderSurvey.style.opacity = '0';
        genderSurvey.style.transform = 'translateY(-15px)';
        genderSurvey.style.zIndex = '3500';
        
        // Force a reflow to ensure the initial state is applied
        void genderSurvey.offsetHeight;
        
        // Then trigger the animation after a very short delay
        setTimeout(() => {
          genderSurvey.style.opacity = '1';
          genderSurvey.style.transform = 'translateY(0)';
          
          // Apply a nice animation class
          genderSurvey.classList.add('slide-in-top');
          
          // Also animate the container
          const genderContainer = genderSurvey.querySelector('.survey-container');
          if (genderContainer) {
            genderContainer.style.opacity = '1';
            genderContainer.style.transform = 'translateY(0)';
          }
        }, 20);
      }
      
      // Handle the LuvMissionSurvey (with slight delay to let the animation start)
      setTimeout(() => {
        if (luvSurvey) {
          if (fromRecommendation) {
            // Keep mounted but hide it
            luvSurvey.style.visibility = 'hidden';
            luvSurvey.style.zIndex = '1000';
          } else {
            // Unmount after animation completes
            setTimeout(() => {
              setShowLuvMissionSurvey(false);
            }, 300);
          }
        }
      }, 50);
    }, 10);
  };

  const handlePlanClick = () => {
    setShowSurvey(true);
    // Add longer delay before hiding popup for smoother transition
    setTimeout(() => {
      handleClosePopup();
    }, 150);
  };

  const handleProfileClick = () => {
    setShowSurvey(true);
    // Add longer delay before hiding popup for smoother transition
    setTimeout(() => {
      setShowProfilesPopup(false);
    }, 150);
  };

  const handleActivityClick = () => {
    setShowSurvey(true);
    // Add longer delay before hiding popup for smoother transition
    setTimeout(() => {
      setShowMatchPopup(false);
    }, 150);
  };

  useEffect(() => {
    if (showPopup || showMatchPopup || showProfilesPopup) {
      const handleOutsideClick = (e) => {
        if (!e.target.closest('.logo-button') && 
            !e.target.closest('.match-popup') && 
            !e.target.closest('.profiles-popup') && 
            !e.target.closest('.feature') && 
            !e.target.closest('.match-popup-buttons')) {
          handleClosePopup();
        }
      };

      document.addEventListener('click', handleOutsideClick);
      
      return () => {
        document.removeEventListener('click', handleOutsideClick);
      };
    }
  }, [showPopup, showMatchPopup, showProfilesPopup]);

  // Add scroll event listener to hide the indicator in shared mission view
  useEffect(() => {
    if (sharedMission && sharedMissionDetailsRef.current) {
      const handleScroll = () => {
        setShowScrollIndicator(false);
      };
      
      const missionDetailsElement = sharedMissionDetailsRef.current;
      missionDetailsElement.addEventListener('scroll', handleScroll, { passive: true });
      
      // Check if content is actually scrollable
      const isScrollable = missionDetailsElement.scrollHeight > missionDetailsElement.clientHeight;
      setShowScrollIndicator(isScrollable);
      
      return () => {
        missionDetailsElement.removeEventListener('scroll', handleScroll);
      };
    }
  }, [sharedMission]);

  return (
    <div className="App">
      {/* Hidden preload image with WebP and fallback */}
      <picture style={{ display: 'none' }}>
        <source srcSet={heartIconWebp} type="image/webp" />
        <img 
          src={heartIconPng}
          alt="" 
          onLoad={() => setImageLoaded(true)}
        />
      </picture>
      
      {/* Laser Animation Container - Positioned first to be behind everything */}
      <div 
        ref={laserContainerRef}
        className={`laser-container ${showLaser ? 'visible' : ''}`} 
      />
      
      {/* Show shared mission if present */}
      {loadingMission && (
        <div className="shared-mission-overlay">
          <div className="shared-mission-container">
            <div className="loading-indicator">Loading mission...</div>
          </div>
        </div>
      )}
      
      {!loadingMission && sharedMission && (
        <div className="shared-mission-overlay" style={{
          backgroundColor: '#fae6ff',
          backdropFilter: 'blur(10px)'
        }}>
          <div className="shared-mission-container" style={{
            boxShadow: '0 0 30px rgba(147, 51, 234, 0.6), 0 0 80px rgba(147, 51, 234, 0.4), 0 0 120px rgba(147, 51, 234, 0.2)',
            backgroundColor: 'white',
            borderRadius: '32px',
            position: 'relative',
            zIndex: '2',
            maxWidth: '520px',
            width: '100%',
            overflow: 'hidden'
          }}>
            <button 
              className="shared-mission-close" 
              onClick={() => {
                // Clear the mission from URL and state
                window.history.pushState({}, '', '/');
                setSharedMission(null);
              }}
            >×</button>
            
            <h2 
              className="shared-mission-title"
              style={{ 
                margin: '0 0 24px 0',
                fontWeight: 'bold', 
                color: 'rgba(147, 51, 234, 0.8)', 
                textShadow: '0 0 5px rgba(147, 51, 234, 0.6), 0 0 15px rgba(147, 51, 234, 0.5), 0 0 20px rgba(147, 51, 234, 0.4)', 
                letterSpacing: '1px',
                fontSize: '28px'
              }}
            >
              Your LUV Mission in SF
            </h2>
            
            <div className="mission-details" ref={sharedMissionDetailsRef} style={{
              backgroundColor: '#f8f4ff',
              borderRadius: '12px',
              padding: '16px 20px'
            }}>
              {/* Add the scroll indicator at the top */}
              {showScrollIndicator && (
                <div className="scroll-indicator">↓ Scroll for more ↓</div>
              )}
              
              <div className="location-info-card" style={{ 
                marginBottom: '24px',
                padding: '24px 12px', 
                borderRadius: '12px', 
                backgroundColor: '#f8f4ff',
                overflow: 'hidden',
                boxShadow: '0 4px 15px rgba(147, 51, 234, 0.1)'
              }}>
                {/* Location Image */}
                {sharedMission.locationDetails && sharedMission.locationDetails.photoUrl ? (
                  <div style={{ 
                    marginTop: '20px',
                    marginBottom: '12px', 
                    overflow: 'hidden', 
                    display: 'flex', 
                    justifyContent: 'center'
                  }}>
                    <img 
                      src={sharedMission.locationDetails.photoUrl}
                      alt={sharedMission.locationDetails.name || 'Location photo'} 
                      style={{ 
                        width: '45%', 
                        height: 'auto', 
                        aspectRatio: '16/9', 
                        objectFit: 'cover', 
                        borderRadius: '8px',
                        boxShadow: '0 2px 8px rgba(147, 51, 234, 0.15)'
                      }}
                    />
                  </div>
                ) : null}
                
                <div style={{ padding: '0 24px' }}>
                  {/* Location Name */}
                  <h3 style={{ 
                    margin: '0 0 8px 0', 
                    color: '#333', 
                    fontSize: '20px', 
                    fontWeight: '700',
                    textAlign: 'center'
                  }}>
                    {sharedMission.locationDetails ? sharedMission.locationDetails.name : sharedMission.location}
                  </h3>
                  
                  {/* Location Description */}
                  <p style={{ 
                    margin: '0 0 8px 0', 
                    color: '#9333ea', 
                    fontSize: '14px',
                    textAlign: 'center'
                  }}>
                    {sharedMission.locationDetails ? 
                      sharedMission.locationDetails.description : 
                      'Your mission location'}
                  </p>
                  
                  {/* Clickable Address with Google Maps Link */}
                  <a 
                    className="maps-link"
                    href={sharedMission.locationDetails && sharedMission.locationDetails.coordinates ? 
                      `https://www.google.com/maps/search/?api=1&query=${sharedMission.locationDetails.coordinates.lat},${sharedMission.locationDetails.coordinates.lng}&query_place_id=${sharedMission.locationDetails.placeId}` : 
                      `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(sharedMission.locationDetails?.formattedAddress || sharedMission.location + ', San Francisco')}`
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ 
                      margin: '0 0 12px 0', 
                      color: '#666', 
                      fontSize: '16px',
                      textAlign: 'center',
                      display: 'block',
                      textDecoration: 'none'
                    }}
                  >
                    <span style={{
                      borderBottom: '1px dotted #666',
                      paddingBottom: '2px'
                    }}>
                      {sharedMission.locationDetails ? 
                        sharedMission.locationDetails.formattedAddress : 
                        'San Francisco, CA'}
                    </span>
                    <span style={{ marginLeft: '6px', fontSize: '14px', color: '#9333ea' }}>↗</span>
                  </a>
                  
                  {/* Hours Section */}
                  {sharedMission.locationDetails && sharedMission.locationDetails.hours && (
                    <div style={{ 
                      margin: '16px 0 0 0',
                      padding: '20px 24px',
                      borderRadius: '12px',
                      backgroundColor: '#f8f4ff',
                      display: 'flex',
                      flexDirection: 'column'
                    }}>
                      <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: '8px'
                      }}>
                        <span style={{
                          color: '#9333ea',
                          fontSize: '18px',
                          marginRight: '8px'
                        }}>⏱</span>
                        <span style={{
                          fontWeight: '600',
                          fontSize: '15px',
                          color: '#333'
                        }}>Hours:</span>
                      </div>
                      <div style={{ 
                        fontSize: '14px',
                        color: '#555',
                        lineHeight: '1.5',
                        paddingLeft: '28px'
                      }}>
                        {typeof sharedMission.locationDetails.hours === 'string' && sharedMission.locationDetails.hours.includes(', ') 
                          ? sharedMission.locationDetails.hours.split(', ').map((day, index) => (
                              <React.Fragment key={index}>
                                {index > 0 && <br />}
                                {day}
                              </React.Fragment>
                            ))
                          : sharedMission.locationDetails.hours
                        }
                      </div>
                    </div>
                  )}
                </div>
              </div>
              
              {/* Highlights Section */}
              {sharedMission.locationDetails && sharedMission.locationDetails.highlights && 
               sharedMission.locationDetails.highlights.length > 0 && (
                <div style={{ 
                  padding: '24px',
                  margin: '0 0 24px 0',
                  backgroundColor: '#f8f4ff',
                  borderRadius: '12px',
                  textAlign: 'left',
                  boxShadow: '0 4px 15px rgba(147, 51, 234, 0.1)'
                }}>
                  <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '12px'
                  }}>
                    <span style={{
                      color: '#f06292',
                      fontSize: '20px',
                      marginRight: '8px'
                    }}>★</span>
                    <span style={{
                      fontWeight: '600',
                      fontSize: '15px',
                      color: '#333'
                    }}>Highlights:</span>
                  </div>
                  <ul style={{ 
                    margin: '0',
                    padding: '0',
                    listStyleType: 'none',
                    color: '#555',
                    textAlign: 'left'
                  }}>
                    {Array.isArray(sharedMission.locationDetails.highlights) ? 
                      sharedMission.locationDetails.highlights.map((highlight, index) => (
                        <li key={index} style={{ 
                          margin: '8px 0',
                          fontSize: '14px',
                          lineHeight: '1.5',
                          paddingLeft: '0px',
                          position: 'relative',
                          display: 'flex',
                          alignItems: 'flex-start',
                          textAlign: 'left'
                        }}>
                          <span style={{
                            color: '#9333ea',
                            marginRight: '6px',
                            fontSize: '14px',
                            lineHeight: '1.5',
                            minWidth: '10px',
                            textAlign: 'center'
                          }}>•</span>
                          <span style={{
                            textAlign: 'left',
                            display: 'block'
                          }}>{highlight}</span>
                        </li>
                      )) : 
                      <li style={{ 
                        margin: '8px 0',
                        fontSize: '14px',
                        lineHeight: '1.5',
                        paddingLeft: '0px',
                        position: 'relative',
                        display: 'flex',
                        alignItems: 'flex-start',
                        textAlign: 'left'
                      }}>
                        <span style={{
                          color: '#9333ea',
                          marginRight: '6px',
                          fontSize: '14px',
                          lineHeight: '1.5',
                          minWidth: '10px',
                          textAlign: 'center'
                        }}>•</span>
                        <span style={{
                          textAlign: 'left',
                          display: 'block'
                        }}>{sharedMission.locationDetails.highlights}</span>
                      </li>
                    }
                  </ul>
                </div>
              )}
              
              {/* Notes Section */}
              {sharedMission.locationDetails && (
                <div style={{ 
                  padding: '24px',
                  margin: '0 0 24px 0',
                  backgroundColor: '#fff9e6',
                  borderRadius: '12px',
                  textAlign: 'left',
                  boxShadow: '0 4px 12px rgba(248, 161, 0, 0.1)'
                }}>
                  <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '12px'
                  }}>
                    <span style={{
                      color: '#f8a100',
                      fontSize: '20px',
                      marginRight: '8px'
                    }}>ⓘ</span>
                    <span style={{
                      fontWeight: '600',
                      fontSize: '15px',
                      color: '#333'
                    }}>Notes:</span>
                  </div>
                  <div style={{ 
                    fontSize: '14px',
                    color: '#555',
                    lineHeight: '1.5',
                    textAlign: 'left'
                  }}>
                    {sharedMission.locationDetails.notes || "Outdoor venue, free admission. Limited parking available. The north end is clothing-optional. Accessible by MUNI 29 bus. Strong currents make swimming unsafe, but perfect for romantic shoreline walks. Bring a blanket and picnic."}
                  </div>
                </div>
              )}
              
              <div style={{ padding: '0 5px' }}>
                {/* Mission Qualifier Section */}
                <div style={{ 
                  backgroundColor: '#f8f4ff',
                  borderRadius: '12px',
                  padding: '24px 20px',
                  margin: '24px 0 16px 0',
                  textAlign: 'center',
                  position: 'relative',
                  overflow: 'hidden',
                  boxShadow: '0 4px 15px rgba(147, 51, 234, 0.1)'
                }}>
                  <div style={{
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    bottom: 0,
                    width: '8px',
                    background: 'linear-gradient(to bottom, #a855f7, #ec4899)'
                  }}></div>
                  
                  <h3 style={{
                    fontSize: '22px',
                    color: '#9333ea',
                    fontWeight: '600',
                    marginTop: '4px',
                    marginBottom: '16px'
                  }}>
                    Mission Qualifier
                  </h3>
                  
                  <p style={{
                    fontSize: '18px',
                    color: '#333',
                    margin: '0'
                  }}>
                    {sharedMission.missionProtocol || sharedMission.qualifier}
                  </p>
                </div>
                
                {/* Hint text under Mission Qualifier */}
                <p style={{
                  fontSize: '14px',
                  color: '#666',
                  margin: '12px 0 24px 0',
                  textAlign: 'center',
                  lineHeight: '1.5'
                }}>
                  Required to complete your mission. You will receive a mission task when you meet in person. Bring this with you for a fun experience and rewards!
                </p>
                
                {/* Sign up link */}
                <div style={{
                  margin: '16px 0 24px 0',
                  textAlign: 'center'
                }}>
                  <a 
                    href="#"
                    onClick={() => {
                      window.history.pushState({}, '', '/');
                      setSharedMission(null);
                      setShowLuvMissionSurvey(true);
                    }}
                    style={{
                      color: '#9333ea',
                      fontSize: '16px',
                      fontWeight: '500',
                      textDecoration: 'underline',
                      display: 'inline-block',
                      padding: '8px 0',
                      cursor: 'pointer'
                    }}
                  >
                    Sign up to receive your mission task and win rewards
                  </a>
                </div>
              </div>
            </div>
            
            <div className="shared-mission-actions" style={{
              padding: '0px 0 5px 0',
              display: 'flex',
              justifyContent: 'center'
            }}>
              {/* Create Your Own Mission button */}
              <button 
                onClick={() => {
                  window.history.pushState({}, '', '/');
                  setSharedMission(null);
                  setShowLuvMissionSurvey(true);
                }}
                style={{
                  backgroundColor: '#9333ea',
                  color: 'white',
                  fontWeight: 'bold',
                  padding: '12px 24px',
                  borderRadius: '24px',
                  border: 'none',
                  fontSize: '16px',
                  cursor: 'pointer',
                  boxShadow: '0 4px 12px rgba(147, 51, 234, 0.3)',
                  width: '80%',
                  maxWidth: '300px'
                }}
              >
                Create Your Own Mission
              </button>
            </div>
          </div>
        </div>
      )}
      
      {!sharedMission && (
        <>
          <div className="location">San Francisco, CA</div>
          <div className="content-wrapper">
            <div className="logo-button-container">
              <div className="glow-layer-1">LUV.WTF</div>
              <div className="glow-layer-2">LUV.WTF</div>
              <div className="glow-layer-3">LUV.WTF</div>
              <div className="glow-layer-4">LUV.WTF</div>
              <div className="glow-layer-5">LUV.WTF</div>
              <button 
                className="logo-button" 
                onClick={handleLogoClick}
                type="button"
              >
                LUV.WTF
              </button>
            </div>
            <div className="tagline-flicker">
              <span className="tagline-text">WHEN LOVE DOESN'T MAKE SENSE <br />GO ON A LUV MISSION</span>
            </div>
            <button className="join-button" onClick={handleJoinClick}>START<br />MISSION</button>
            <a href="#" className="learn-more-link" onClick={(e) => {
              e.preventDefault();
              setShowLearnMore(true);
            }}>
              Learn more
            </a>
          </div>
          
          {/* Survey Components */}
          {showSurvey && (
            <Survey 
              onClose={handleSurveyClose} 
              className="higher-survey gender-survey"
            />
          )}
          {showLuvMissionSurvey && (
            <LuvMissionSurvey 
              onClose={handleLuvMissionSurveyClose}
              onFindMatch={handleFindMatch}
              showLearnMore={false}
              onLearnMoreClose={handleLearnMoreClose}
            />
          )}

          {/* Learn More Popup */}
          {showLearnMore && (
            <LearnMorePopup
              onClose={handleLearnMoreClose}
              onTry={handleLearnMoreTry}
            />
          )}
          
          <div 
            className={`heart-popup-overlay ${showPopup || showMatchPopup || showProfilesPopup ? 'visible' : ''}`} 
            onClick={handleClosePopup}
          />
          <div className={`heart-popup ${showPopup ? 'visible' : ''}`}>
            <div className="heart-popup-content">
              Join if you're looking to form meaningful connections with real people. Get two profiles thoughtfully chosen for you every week
            </div>
          </div>
          
          {/* Match Popup */}
          <div className={`match-popup ${showMatchPopup ? 'visible' : ''}`}>
            <button className="close-button" onClick={handleClosePopup}>×</button>
            <div className="match-popup-content">
              <div className="match-profile">
                <div className="profile-image-container">
                  <picture>
                    <source srcSet={johnImageWebp} type="image/webp" />
                    <img src={johnImagePng} alt="John" className="profile-image" />
                  </picture>
                </div>
                <div className="profile-info">
                  <h2>John, 25</h2>
                  <p>Jr. Product Manager</p>
                  <p>Wants to explore San Francisco parks and nature</p>
                  <button 
                    className="profile-link" 
                    onClick={handleActivityClick}
                  >
                    See full profile
                  </button>
                </div>
              </div>
              
              <div className="match-interests">
                <p>Both you and <span className="highlight">John</span> like <span className="highlight">nature</span> and <span className="highlight">afternoon coffee</span>.</p>
                <p><span className="highlight">Chat</span> and <span className="highlight">plan</span> together!</p>
                <p className="edit-hint">(tap activity to edit)</p>
              </div>
              
              <div className="match-activities">
                <div className="activity clickable" onClick={handleActivityClick}>
                  <picture>
                    <source srcSet={andytownImageWebp} type="image/webp" />
                    <img src={andytownImageJpg} alt="Andytown Coffee" className="activity-image" />
                  </picture>
                  <p>Andytown<br />Coffee</p>
                </div>
                
                <div className="activity-separator">&</div>
                
                <div className="activity clickable" onClick={handleActivityClick}>
                  <picture>
                    <source srcSet={sunsetImageWebp} type="image/webp" />
                    <img src={sunsetImageJpeg} alt="Ocean Beach Sunset" className="activity-image" />
                  </picture>
                  <p>Ocean Beach<br />Sunset</p>
                </div>
              </div>
              
              <div className="match-popup-buttons">
                <button className="decline-button" onClick={handleClosePopup}>DECLINE</button>
                <button className="plan-button" onClick={handlePlanClick}>PLAN</button>
              </div>
            </div>
          </div>
          
          {/* Profiles Popup */}
          <div className={`profiles-popup ${showProfilesPopup ? 'visible' : ''}`}>
            <button className="close-button" onClick={handleClosePopup}>×</button>
            <div className="profiles-popup-content">
              <div className="profiles-header">
                <p>
                  <span className="highlight">Get two profiles</span> thoughtfully suggested for you <span className="highlight">every week</span> and <span className="highlight">plan a unique date</span> in SF using our AI date planning tools!
                </p>
              </div>
              
              <div className="profile-card clickable" onClick={handleProfileClick}>
                <div className="profile-image-container">
                  <picture>
                    <source srcSet={johnImageWebp} type="image/webp" />
                    <img src={johnImagePng} alt="John" className="profile-image" />
                  </picture>
                </div>
                <div className="profile-info">
                  <h2>John, 25</h2>
                  <p>Jr. Product Manager</p>
                  <p>Wants to explore San Francisco parks and nature</p>
                </div>
              </div>
              
              <div className="profile-card clickable" onClick={handleProfileClick}>
                <div className="profile-image-container">
                  <picture>
                    <source srcSet={timImageWebp} type="image/webp" />
                    <img src={timImagePng} alt="Tim" className="profile-image" />
                  </picture>
                </div>
                <div className="profile-info">
                  <h2>Tim, 27</h2>
                  <p>Marketing Consultant</p>
                  <p>Likes museums, fine arts, and theatre</p>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default App;
