import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyD1XjB24skWNOyu9OLV1nbpgi_Eaqfif1s",
    authDomain: "luvw-1575a.firebaseapp.com",
    projectId: "luvw-1575a",
    storageBucket: "luvw-1575a.firebasestorage.app",
    messagingSenderId: "166227542163",
    appId: "1:166227542163:web:8ef4bc48fb8bde368c4d66",
    measurementId: "G-WB769P38GJ"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const analytics = getAnalytics(app);

export { db, auth, analytics };